import React from "react";

import { useCookies} from 'react-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Login from './pages/Login'
import Register from './pages/Register'
import ResetPassword from './pages/ResetPassword'
import ForgetPassword from './pages/ForgetPassword'
import Settings from './pages/Settings'

import Home from './pages/Home'
import Companies from './pages/Companies'
import Users from "./pages/Users";

export default function App() {
  const [cookies, setCookie,removeCookie] = useCookies(['token']);

function Logout() {
  removeCookie("superadmintoken",{path:'/'})
  return ( <Redirect to='/login' />)
}


  return (
      <Router>
        <Switch>
          <Route path="/register">
            <Register token={cookies.superadmintoken} />
          </Route>
          <Route path="/login">
            <Login token={cookies.superadmintoken} />
          </Route>
          <Route path="/forgot">
            <ForgetPassword />
          </Route>
          <Route path="/resetPassword/:slug">
            <ResetPassword/>
          </Route>
          <Route path="/admin/logout">
            <Logout />
          </Route>
          <Route path="/companies">
            <Companies token={cookies.superadmintoken} />
          </Route>
          <Route path="/users">
            <Users token={cookies.superadmintoken} />
          </Route>
          <Route path="/settings">
            <Settings token={cookies.superadmintoken} cookie={cookies} />
          </Route>
          <Route path="/">
            <Home token={cookies.superadmintoken} cookie={cookies}/>
          </Route>
        </Switch>
    </Router>
  );
}

