import React, {useState, useEffect,useRef} from 'react';
import {useHistory } from "react-router-dom";
import { Button, InputBase, Input, Box,
        Grid, Divider, Select, MenuItem,
        Checkbox, FormControlLabel } from '@material-ui/core';
import company from '../../pages/client/company'
import CustomAvatar from '../avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import AlertMessage from './message';
import placeholder from '../../assets/images/placeholder.jpg'
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { userRoles } from './formOptions';
import SendIcon from '@material-ui/icons/Send';
import InfoIcon from '@material-ui/icons/Info';
import users from '../../pages/client/Auth'
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';

const GreyCheckbox = withStyles({
  root: {
    color: grey[400],
    border: 'none',
    '&$checked': {
      color: 'none',
      border: 'none',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label': {
            marginTop: theme.spacing(0),
        },
    },
}))(InputBase);

const useStyles = makeStyles({
    root: {
        maxHeight: '570px',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: '32px',
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.input,
        }
    },
    sectionLabel:{
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
    },
    input: {
        marginTop: theme.spacing(1),
        fontWeight: '500',
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.input,
        padding: '8px 12px',
        position: 'relative',
        width: '420px',
        color: theme.palette.text.header,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            opacity: '0.8',
            borderRadius: theme.shape.borderRadius,
        },
    },
    upload: {
        visibility: 'hidden',
        width: 0,
    },
    uploadLabel: {
        padding: '8px 24px',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginLeft: theme.spacing(4),
        fontWeight: '500',
        fontSize: '14px',
    },
    label: {
        display: 'block',
        margin: '6px 16px',
        fontWeight: 'normal',
    },
    button: {
        padding: '12px 40px',
    },
    pr16: {
        paddingRight: '16px',
    },
    padLeft: {
        paddingLeft: '16px',
    },
    fields: {
        margin: theme.spacing(2),
    },
    heading: {
        ...theme.typography.h4,
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
    divider: {
        backgroundColor: theme.palette.background.input,
        margin: '8px auto',
    },
    select: {
        marginTop: theme.spacing(1),
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.input,
        padding: '8px 0 8px 12px',
        position: 'relative',
        width: '420px',
        color: theme.palette.text.header,
        fontWeight: '500',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: theme.palette.background.input,
            borderRadius: theme.shape.borderRadius,
            color: 'white',
        },
    },
    selectOptions: {
        backgroundColor: theme.palette.background.input,
        borderColor: theme.palette.secondary.main,
        borderRadius: '8px',
        border: 'none',
        color: theme.palette.text.muted,
        padding: '0',
        width: '420px',
        marginLeft: '-12px',
        marginTop: '-2px',
        boxShadow: 'none',
        "& .MuiList-padding": { paddingTop: '0px', paddingBottom: '0px'},
        "& .MuiListItem-root": {
            borderRadius: 0,
            padding: '10px 26px 10px 12px',
            width: '100%',
            border: 'none',
        },
        "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "transparent"
        },
        "select &:focus, select &:hover" : {
            border: 'none',
        }
    },
    checkIcon: {
        borderRadius: 3,
        width: 24,
        height: 24,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#28353D',
        '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E" +
            "%3Crect width='24' height='24' rx='2' fill='#28353D'/%3E" +
            "%3C/svg%3E\")",
        content: '""',
        },
        '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
        },
        'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#28353D',
        // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrYm94Ij4KPHJlY3QgaWQ9IlJlY3RhbmdsZSAyODc5MTcyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSIyIiBmaWxsPSIjMjgzNTNEIi8+CjxnIGlkPSJ0aWNrIDEiIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPgo8ZyBpZD0iR3JvdXAiPgo8ZyBpZD0iR3JvdXBfMiI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xOS43NjU3IDYuMzU5MzNDMTkuNDUzMyA2LjA0Njg5IDE4Ljk0NjggNi4wNDY4OSAxOC42MzQzIDYuMzU5MzNMOS4wNDk4MyAxNS45NDM5TDUuMzY1NyAxMi4yNTk4QzUuMDUzMyAxMS45NDc0IDQuNTQ2OCAxMS45NDc0IDQuMjM0MzMgMTIuMjU5OEMzLjkyMTg5IDEyLjU3MjIgMy45MjE4OSAxMy4wNzg3IDQuMjM0MzMgMTMuMzkxMUw4LjQ4NDE0IDE3LjY0MDlDOC43OTY0NSAxNy45NTMzIDkuMzAzMzMgMTcuOTUzMSA5LjYxNTUyIDE3LjY0MDlMMTkuNzY1NyA3LjQ5MDdDMjAuMDc4MSA3LjE3ODMgMjAuMDc4MSA2LjY3MTc3IDE5Ljc2NTcgNi4zNTkzM1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9nPgo8L2c+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDQpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')",
        content: '""',
        },
    },
    checkLabel: {
        color: 'white',
        fontWeight: '500',
        fontSize: '14px',
    },
    permissions: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1,2),
        fontSize: '14px',
        color: 'white',
        wordBreak: 'break-word',
        background: 'rgba(226, 96, 96, 0.5)',
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: theme.spacing(1),
        width: '385px',
        '& .icon': {
            marginRight: theme.spacing(1),
        }
    }
})

const phoneInputStyles = {
    backgroundColor: theme.palette.background.input,
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px `,
    border: `1px solid ${theme.palette.background.input}`,
    color: 'white',
    borderRadius: '10px',
    fontSize: '1rem',
    width: '420px',

}
const phoneButtonStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: '10px 0 0 10px',
    fontSize: '1rem',
}

const phoneDropdownStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    paddingRight: theme.spacing(2),
    fontSize: '1rem',
}
// styling for select menu
const MenuProps = {
    PaperProps: {
        style: {
        width: '420px'
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};



const defaultMessage = {
    // title: 'Failed', content:`Please try again`, type:'red', open: true
    // title: 'Successful!', content:'New rider has been added succesfully!', type:'green',open: true
    title: null,
    content: null,
    type: null,
    open: false,
}

const EditUser = (props) => {
    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

        return [ htmlElRef, setFocus ] 
    }

    const classes = useStyles();
    const {token, data, companyList} = props;
  
    const defaultValues = {
        company_id: data.company_id,
        role: data.role,
        first_name: data.first_name,
        last_name: data.last_name,
        mobile_no: data.mobile_no,
        email: data.email,
        title: data.title,
        owner: data.owner,
    }
    const { handleSubmit, control, formState: { errors }, register, setError, clearErrors,reset, watch, getValues, setValue } = useForm({
        defaultValues
    });
    const [preview, setPreview] = useState(placeholder);
    const [inputRef, setInputFocus] = useFocus();
    const [status,setStatus] = useState(0);
    const [role, setRole] = useState(getValues('role'))
    const [owner, setOwner] = useState(getValues('owner'));
    const [message, setMessage] = useState({
        ...defaultMessage
    });
    const history = useHistory();
    const LoadData = props.LoadData;
    MenuProps.PaperProps.className = classes.selectOptions;

    function onFileSelect(e) {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        // console.log(selectedFile.name)
        setPreview(URL.createObjectURL(selectedFile))
        setValue('image',selectedFile);
        props.onchange(true)
    }

    const checkCompany = (data) => {
        if (data.company_id === 'none') {
            setError("company_id", {type: "required"})
            return false;
        } else {
            return true;
        }
    }

    const checkRole = (data) => {
        if (data.role === 'none') {
            setError("role", {type: "required"})
            return false;
        } else {
            return true;
        }
    }
    const postValidate = async (data) => {

        let companySelected = checkCompany(data);
        let roleSelected = checkRole(data);
        if (companySelected) {
            if (roleSelected) {
                if (data.role === 'admin' && owner) {
                    let userdata = await users.getList(token,{company_id:data.company_id});
                    let validate = async (data) => await checkOwner(data);
                    validate(userdata).then((hasCompany)=>{
                        console.log('hasCompany',hasCompany)
                        if (hasCompany) {
                            return false;
                        } else {
                            return true;
                        }
                    })
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }

    const checkOwner = (data) => {
        let hasOwner;
        let compUsers = data.data.data;
        hasOwner = compUsers.length > 0 && compUsers.filter((x)=>x.owner===true).length > 0;
        if (hasOwner) {
            // alert('Company',hasOwner)
            // setError("role", {type: "validate"})
            setMessage({title: 'Failed', content:`Owner already exist for this company. You cannot set more than one Owner for a company. Please uncheck "Owner" and Save again.`, type:'red', open: true,})
            setStatus(2)
            // setOwner(false)
        }
        return hasOwner;
    }

    const onFormSubmit = formdata => {
        // e.preventDefault();
        // onSubmit(data);
        const validated = async (formdata) => await postValidate(formdata)
        
        validated(formdata).then((valid) => {
            console.log('valid',valid)
            if (valid) {
                console.log('submit',formdata,owner)
                // const formData = new FormData();
                // for ( var key in data ) {
                //     formData.append(key, data[key]);
                // }
                formdata.owner = owner;
                users.putUpdate(formdata,props.token,data._id).then((data) => {
                    setMessage({title: 'Successful!', content:'User information updated succesfully!', type:'green',open: true,})
                    props.onchange(false);
                    setStatus(1)
                }).catch((e) => {
                    setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red', open: true,})
                    setStatus(2)
                });
        }
        })
    }

    useEffect(()=> {
        setInputFocus()
        //console.log('rendered',reset)
    },[])

    const handleClose = () => {
      setMessage({
          ...defaultMessage
      });
      if (props.page === 'home') {
          history.push('/users')
      } else {
          props.setRefresh && props.setRefresh(true);
      }
      setStatus(0)
    };
    const closeForm = () => {
        // close all modal 
        handleClose()
        props.handleClose()
        props.setRefresh(true);
        // set Status back to original
        setStatus(0)
    }
    
    const handleSend = () => {
        //insert send invitation method here
        const {email,company_id} = data
        users.postInvite({email,company_id},props.token).then(() => {
            setMessage({title: 'Successful!', content:'User invited succesfully!', type:'green',open: true,})
            props.onchange(false);
            setStatus(1)
        }).catch((e) => {
            setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red', open: true,})
            setStatus(2)
        })
        // console.log({email,company_id});
        // alert('email sent')
    }
    const handleOwnerStatus = () => {
        setOwner(!owner)
    }

    const handleRole = (e) => {
        setRole(e.target.value)
    }
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container direction="row">
                <Grid item sx>
                    <div className={classes.fields}>
                        <label htmlFor="company_id" className={classes.label}>Company Name *</label>
                        <Controller
                        control={control}
                        name="company_id"
                        value={getValues('company_id')}
                        render={({ field }) =>(
                            <Select
                                classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
                                className={classes.select}
                                id="company_id"
                                input={<BootstrapInput />}
                                MenuProps={MenuProps}
                                IconComponent={ExpandMoreIcon}
                                {...field}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    props.onchange(true)
                                }}
                            >   <MenuItem value="none" >
                                        <em>None</em>
                                    </MenuItem>
                                {companyList.map((obj, key) => 
                                    <MenuItem value={obj._id} key={key}>
                                        {obj.name}
                                    </MenuItem>
                                )}
                            </Select>
                        )}
                        />
                        <div className={classes.error}>
                            {errors.company_id?.type === 'required' && "*Required"}
                        </div>
                    </div>
                </Grid>
                <Grid item sx={6}>
                    <div className={classes.fields}>
                        <label className={classes.label}>Role *</label>
                        <Controller
                        control={control}
                        name="role"
                        value={getValues('role')}
                        render={({ field }) =>(
                            <Select
                                classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
                                className={classes.select}
                                id="role"
                                input={<BootstrapInput />}
                                MenuProps={MenuProps}
                                IconComponent={ExpandMoreIcon}
                                {...field}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    props.onchange(true);
                                    handleRole(e)
                                }}
                            >
                                {userRoles.map((obj, key) => 
                                    <MenuItem value={obj.value} key={key}>
                                        {obj.label}
                                    </MenuItem>
                                )}
                            </Select>
                        )}
                        />
                        <div className={classes.error}>
                            {errors.role?.type === 'required' && "*Required"}
                            {errors.role?.type === 'validate' && "*One company can only have one owner"}
                        </div>
                        {role === 'admin' &&
                        <Box>
                            <FormControlLabel
                                control={
                                <GreyCheckbox
                                checked={owner}
                                onChange={handleOwnerStatus}
                                name="owner"
                                checkedIcon={<span className={clsx(classes.checkIcon, classes.checkedIcon)} />}
                                icon={<span className={classes.checkIcon} />}
                                />
                                }
                                label="Owner" classes={{label: classes.checkLabel, disabled: classes.checkLabel }}
                            />
                        </Box>
                        }
                        {role === 'admin' && !owner ?
                            <Box className={classes.permissions}><InfoIcon className="icon" color="secondary" /> This user has full access and this account can add, edit, and delete users (except owner). </Box>
                        : role === 'admin' && owner ?
                            <Box className={classes.permissions}><InfoIcon className="icon" color="secondary" /> Owner has the highest access and this account can add, edit, and delete any user.</Box>
                        : role === 'manager' ? <Box className={classes.permissions}><InfoIcon className="icon" color="secondary" /> This user cannot add, edit and delete users and will not see "User &amp; Permissions" menu.<br/>This user can only view Profile Company in Settings.</Box>
                        : null}
                    </div>
                </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <div className={classes.sectionLabel}>
                    User Details
                </div>
                <Grid container direction="row">
                    <Grid item sx={6}>
                        <div className={classes.fields}>
                            <label htmlFor="first-name" className={classes.label}>First Name *</label>
                            <Controller
                                control={control}
                                name="first_name"
                                rules={{required: false}}
                                render={({ field }) => (
                                    <InputBase
                                    className={classes.input}
                                    id="first_name"
                                    type="text"
                                    {...field}
                                    onChange={(e) => {field.onChange(e);props.onchange(true)}} 
                                    placeholder="First Name"
                                    />
                                )}
                            />
                            <div className={classes.error}>
                                {errors.first_name?.type === 'required' && "*Required"}
                            </div>
                        </div>
                    </Grid>
                    <Grid item sx={6}>
                        <div className={classes.fields}>
                            <label htmlFor="last-name" className={classes.label}>Last Name *</label>
                            <Controller
                                control={control}
                                name="last_name"
                                rules={{required: false}}
                                render={({ field }) => (
                                    <InputBase
                                    className={classes.input}
                                    id="last_name"
                                    type="text"
                                    {...field}
                                    onChange={(e) => {field.onChange(e);props.onchange(true)}} 
                                    placeholder="Last Name"
                                    />
                                )}
                            />
                            <div className={classes.error}>
                                {errors.last_name?.type === 'required' && "*Required"}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item sx={6}>
                    <div className={classes.fields}>
                        <label htmlFor="mobile" className={classes.label}>Mobile Number</label>
                        <Controller
                                name="mobile_no"
                                control={control}
                                render={({field}) => {
                                return (
                                    <PhoneInput
                                        {...field}
                                        placeholder="+65 9123 4567"
                                        inputProps={{
                                            name: 'mobile_no',
                                        }}
                                        country={'sg'}
                                        onChange={(e)=>{field.onChange(e);props.onchange(true)}}
                                        containerStyle={{marginTop: '12px'}}
                                        inputStyle={phoneInputStyles}
                                        buttonStyle={phoneButtonStyles}
                                        dropdownStyle={phoneDropdownStyles}
                                        value={field.value}
                                    />
                                );
                                }}
                            />
                        </div>
                    </Grid>
                <Grid item sx={6}>
                <div className={classes.fields}>
                    <label htmlFor="email" className={classes.label}>Email *</label>
                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: false,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        }}
                        render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="email"
                                type="text"
                                placeholder="john@doe.com"
                                {...field}
                                onChange={(e)=>{field.onChange(e);props.onchange(true)}}
                                />}
                            />
                        <div className={classes.error}>
                        {errors.email?.type === 'required' && "Required"}
                        </div>
                        <div className={classes.error}>
                            {errors.email?.type === 'pattern' && "Invalid Email Address"}
                        </div>
                </div>
                </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item sx={6}>
                    <div className={classes.fields}>
                        <label htmlFor="title" className={classes.label}>Job Title</label>
                        <Controller
                                control={control}
                                name="title"
                                value={getValues('title')}
                                rules={{required: false}}
                                render={({ field }) => (
                                    <InputBase
                                    className={classes.input}
                                    id="title"
                                    type="text"
                                    {...field}
                                    onChange={(e) => {field.onChange(e);props.onchange(true)}} 
                                    placeholder="Job Title"
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.fields}>
                    <Button type="submit" className={classes.button} variant="contained" color="primary">Save</Button> &nbsp;
                    <Button type="button" className={classes.button} variant="contained" color="secondary" onClick={handleSend}><SendIcon fontSize="small" /> &nbsp; Send Invitation</Button>
                </div>
            </form>
            <AlertMessage
                open={message.open}
                close={handleClose}
                title={message.title}
                titleStyle={message.type}
                ariaLabelledby="edit-user-message"
                ariaDescribedby="edit-user-message">
                    <Box textAlign="center">
                        {message.content}
                    <Box mt={4}>
                    <Button variant="contained" color="primary" onClick={handleClose}>OK</Button>
                    </Box>
                </Box>
            </AlertMessage>
        </div>
    )
};

export default EditUser;