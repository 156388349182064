import React,{useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch, FormControlLabel } from '@material-ui/core';
import { theme } from '../theme/theme'

const IOSSwitch = withStyles({
  root: {
    width: 56,
    height: 32,
    padding: 1,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    transform: 'translateX(2px)',
    color: '#E26060',
    '&$checked': {
        transform: 'translateX(22px)',
        color: '#24ABA3',
      '& + $track': {
        backgroundColor: '#28353D',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#24ABA3',
      border: '6px solid #24ABA3',
      boxShadow: '2px 2px 20px rgba(36, 171, 163, 0.3)',
    },
  },
  thumb: {
    width: 26,
    height: 26,
  },
  track: {
    borderRadius: 32,
    border: '1px solid #28353D',
    backgroundColor: '#28353D',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
})(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CustomSwitch = ({value,name,id,putSwitch}) => {
    const [switchOn, setSwitchOn] = React.useState(true);
    useEffect(() => {
      setSwitchOn(value);
    },[])
    console.log(switchOn)
    const handleChange = (event) => {
      let update = {};
      update[name] = event.target.checked;
      if (putSwitch) {
        putSwitch({update,query: { _id:id }})
      } else {
        console.log("not exist")
      }
 
      setSwitchOn(event.target.checked);
    };
    return(
        <FormControlLabel
            control={<IOSSwitch checked={value} onChange={handleChange} />}
        />
    )
};

export default CustomSwitch;