import React, { Fragment, useState } from "react";
import lightBlue from "@material-ui/core/colors/lightBlue";

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton, makeStyles, Popper, TextField } from "@material-ui/core";
import { FiCalendar } from 'react-icons/fi';
import { IconContext } from "react-icons";
import { theme } from "../../theme/theme"
import { ThemeProvider } from "styled-components";
import { createMuiTheme } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.header,
    '& .MuiPickersCalendarHeader-transitionContainer': {
      width: '120px',
    },
    '&::-webkit-calendar-picker-indicator': {
        filter: 'invert(1)',
    },
  },
  square: {
    background: theme.palette.primary.main,
    color: '#587578',
    borderRadius: '5px',
    padding: '6px',
  },
  squareRed: {
    background: theme.palette.secondary.main,
    color: '#587578',
    borderRadius: '5px',
    padding: '6px',
  },
  button: {
      background: 'none',
      color: theme.palette.text.primary,
  },
  okButton: {
    background: theme.palette.primary.main,
    color: theme.palette.text.header,
  },
  input: {
        marginTop: theme.spacing(0),
        border: '1px solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.dark,
        padding: '10px 26px 10px 12px',
        position: 'relative',
        textDecoration: 'none',
        width: '340px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&.map': {
          borderColor: theme.palette.background.input,
          backgroundColor: theme.palette.background.input,
           width: '140px',
           fontSize: '0.8em',
           padding: '4px 0 4px 12px ',
           '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
              border: 'collapse',
              borderColor: 'transparent',
              backgroundColor: theme.palette.background.input,
              borderRadius: theme.shape.borderRadius,
          },
        },
        '&.primary': {
          borderColor: theme.palette.background.input,
          backgroundColor: theme.palette.background.input,
          width: '400px',
          padding: '6px 8px 6px 12px ',
          fontSize: '1rem',
          marginTop: theme.spacing(1),
           '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
              border: 'collapse',
              borderColor: 'transparent',
              backgroundColor: theme.palette.background.input,
              borderRadius: theme.shape.borderRadius,
          },
        },
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: theme.shape.borderRadius,
        },
  },
  inputText:{
    color: 'white',
    fontSize: '1.125rem',
  },
  inputPrimary: {
    color: 'white',
    fontSize: '0.875rem',
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

const ModalProps = {
    PaperProps: {
        style: {
            backgroundColor: '#28353D',
            color: '#fff',
        }
    },
}

const InputDatePicker = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  console.log('props',props.value)
  const [selectedDate, handleDateChange] = useState(props.value || null);


  const handleDateSelect = (date) => {
    handleDateChange(date)
    props.onDatechange(moment(date).format("DD/MM/yyyy"));
  }
  return (
      <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.root}>
        <KeyboardDatePicker
            disableToolbar
            variant="dialog"
            format="dd/MM/yyyy"
            margin="normal"
            id={props.id}
            value={selectedDate}
            maxDate={new Date()}
            onChange={handleDateSelect}
            // onBlur={handleDateChange}
            // inputProps={...inputProps}
            InputProps={{
              disableUnderline:true,
              classes: {
                input: props.classname === 'map' ? classes.inputMap : classes.inputText,
              }
            }}
            DialogProps={ModalProps}
            leftArrowButtonProps={{classes: {root: classes.button}}}
            rightArrowButtonProps={{classes: {root: classes.button}}}
            className={props.classname === 'map' ? `${classes.input} map`: props.classname === 'primary' ? `${classes.input} primary`: `${classes.input}`}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
        />

        {/* <DatePicker
            variant="dialog"
            view="month"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            format="d MMM yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            maxDateMessage={new Date()}
            // TextFieldComponent={() => null}
            disableToolbar
            DialogProps={ModalProps}
            leftArrowButtonProps={{classes: {root: classes.button}}}
            rightArrowButtonProps={{classes: {root: classes.button}}}
            inputVariant="filled"
            className={color === 'secondary'? 'secondary' : ''}
        /> */}
        </div>
     </MuiPickersUtilsProvider>
     </ThemeProvider>
  );
}

export default InputDatePicker;
