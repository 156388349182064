import React, { useState,useEffect } from 'react';

//imports for front end
import { Button, InputBase, Input, Box } from '@material-ui/core';
import CustomAvatar from '../avatar';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import avatarImg from '../../assets/images/general-post-office.png'
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles({
    sectionLabel:{
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
    },
    input: {
        marginTop: theme.spacing(1),
        fontWeight: '500',
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.input,
        padding: '8px 12px',
        position: 'relative',
        width: '420px',
        color: theme.palette.text.header,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            opacity: '0.8',
            borderRadius: theme.shape.borderRadius,
        },
    },
    upload: {
        visibility: 'hidden',
        width: 0,
    },
    uploadLabel: {
        padding: '8px 24px',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginLeft: theme.spacing(4),
        fontWeight: '500',
        fontSize: '14px',
    },
    label: {
        display: 'block',
        margin: '6px 16px',
        fontWeight: 'normal',
    },
    button: {
        padding: '12px 40px',
    },
    pr16: {
        paddingRight: '16px',
    },
    padLeft: {
        paddingLeft: '16px',
    },
    fields: {
        margin: theme.spacing(3),
    },
    heading: {
        ...theme.typography.h4,
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
})

const phoneInputStyles = {
    backgroundColor: theme.palette.background.input,
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px `,
    border: `1px solid ${theme.palette.background.input}`,
    color: 'white',
    borderRadius: '10px',
    fontSize: '1rem',
    width: '420px',

}
const phoneButtonStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: '10px 0 0 10px',
    fontSize: '1rem',
}

const phoneDropdownStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    paddingRight: theme.spacing(2),
    fontSize: '1rem',
}

const Profile = ({Company,Contact,onSubmit}) => {
    const classes = useStyles();
    const [preview, setPreview] = useState(avatarImg);
    console.log('company',Company, 'contact',Contact)
    const defaultValues = {
        companyName: Company.name,
        firstName:Contact.first_name,
        lastName:Contact.last_name, 
        mobile: Contact.mobile_no,
        email: Contact.email,
        image: Company.image,
    }

    const { handleSubmit, control, formState: { errors }, register, setError, clearErrors,reset, watch, getValues, setValue } = useForm({
        defaultValues
    });

    useEffect(() => {
        setValue('companyName', Company.name)
        setValue('image', Company.image)
        setPreview(Company.image)
        setValue('firstName',Contact.first_name)
        setValue('lastName',Contact.last_name)
        setValue('mobile',Contact.mobile_no)
        setValue('email',Contact.email)

    },[Contact,Company]);

    function onFileSelect(e) {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        // console.log(selectedFile.name)
        setPreview(URL.createObjectURL(selectedFile))
        setValue('image',selectedFile);
    }

    const onFormSubmit = data => {
        // e.preventDefault();
        onSubmit(data);
        // console.log('submit',data)
    }
    
    console.log(watch(),getValues(),errors)
    return(
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className={classes.fields}>
                <Box display="flex" alignItems="center" >
                    <CustomAvatar img={preview} size="xlarge" className="active" />
                    <Box className={classes.padLeft}>
                        <label for="image" className={classes.uploadLabel}>
                            Change Photo
                            <Controller
                                name="image"
                                control={control}
                                render={({ field }) => (
                                <Input
                                    id="image"
                                    type="file"
                                    className={classes.upload}
                                    {...field}
                                    onChange={(e) => {field.onChange(e);onFileSelect(e)}} 
                                    value={(e) => e.target.value}
                                />)}
                            />
                        </label>
                    </Box>
                </Box>
            </div>
            <div className={classes.fields}>
                <label htmlFor="companyName" className={classes.label}>Company Name</label>
                <Controller
                    control={control}
                    name="companyName"
                    rules={{required: true}}
                    render={({ field }) => (
                        <InputBase
                        value={getValues('companyName')}
                        className={classes.input}
                        id="companyName"
                        type="text"
                        {...field}
                        placeholder="Company Name"
                        />
                    )}
                />
                <div className={classes.error}>
                    {errors.companyName?.type === 'required' && "*Required"}
                </div>
            </div>
            <div className={classes.sectionLabel}>
                Contact Person
            </div>
            <div className={classes.fields}>
                <label htmlFor="first-name" className={classes.label}>First Name</label>
                <Controller
                    control={control}
                    name="firstName"
                    rules={{required: false}}
                    render={({ field }) => (
                        <InputBase
                        value={getValues('firstName')}
                        className={classes.input}
                        id="firstName"
                        type="text"
                        {...field}
                        placeholder="First Name"
                        />
                    )}
                />
            </div>
            <div className={classes.fields}>
                <label htmlFor="last-name" className={classes.label}>Last Name</label>
                <Controller
                    control={control}
                    name="lastName"
                    rules={{required: false}}
                    render={({ field }) => (
                        <InputBase
                        value={getValues('lastName')}
                        className={classes.input}
                        id="lastName"
                        type="text"
                        {...field}
                        placeholder="Last Name"
                        />
                    )}
                />
            </div>
            <div className={classes.fields}>
                <label htmlFor="mobile" className={classes.label}>Mobile Number</label>
                <Controller
                        name="mobile"
                        control={control}
                        value={getValues('mobile')}
                        render={({field}) => {
                        return (
                            <PhoneInput
                                {...field}
                                placeholder="+65 9123 4567"
                                inputProps={{
                                    name: 'mobile',
                                }}
                                country={'sg'}
                                onChange={(e)=>{field.onChange(e);}}
                                containerStyle={{marginTop: '12px'}}
                                inputStyle={phoneInputStyles}
                                buttonStyle={phoneButtonStyles}
                                dropdownStyle={phoneDropdownStyles}
                                value={field.value}
                            />
                        );
                        }}
                    />
            </div>
            <div className={classes.fields}>
                <label htmlFor="email" className={classes.label}>Email</label>
                <Controller
                    control={control}
                    name="email"
                    rules={{
                        required: false,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    }}
                    render={({ field }) =>
                        <InputBase
                            value={getValues('email')}
                            className={classes.input}
                            id="email"
                            type="text"
                            placeholder="john@doe.com"
                            {...field}
                            />}
                        />
                    <div className={classes.error}>
                        {errors.email?.type === 'pattern' && "Invalid Email Address"}
                    </div>
            </div>
            <div className={classes.fields}>
                <Button type="submit" className={classes.button} variant="contained" color="primary">Save</Button>
            </div>
        </form>
    )
};

export default Profile;