import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Backdrop, Fade, IconButton } from '@material-ui/core';
import { theme } from '../theme/theme'
import CloseIcon from '@material-ui/icons/Close';
import AlertDialog from './forms/dialog';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerPrimary: {
        color: '#FFF',
        fontWeight: 500,
        margin: '0',
    },
  headerSecondary: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
        margin: '0',
    },
  paper: {
    backgroundColor: theme.palette.background.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
        background: "#283637",
        color: '#6F8A8F',
        padding: '8px',
    },
    alignRight: {
        textAlign: 'end',
    }
});

const CustomModal = (props) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)
    const FormisChanged = props.formStatus;
    const handleCloseForm = () => {
        if (FormisChanged) {
            setOpenDialog(true)
        } else {
            props.close();
        }
    };

    const handleDialog = (options) => {
        setOpenDialog(false)
        if (options === 'close') {
            props.close()
        } else if (options === 'save') {
            return;
        }
    }

    return (
        <>
        <Modal
            aria-labelledby={props.ariaLabelledby}
            aria-describedby={props.ariaDescribedby}
            className={classes.modal}
            open={props.open}
            onClose={props.close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout:500,
            }}
            >
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <Box display="flex" justifyContent="space-between" p={2}>
                        <h1 className={
                            props.titleStyle === 'secondary' ? classes.headerSecondary : 
                            classes.headerPrimary
                        }>
                            {props.title}
                        </h1>
                        <Box>
                            <IconButton className={classes.iconButton} onClick={handleCloseForm}><CloseIcon /></IconButton>
                        </Box>
                        {/* left column */}
                    </Box>
                {/* <Box ><IconButton className={classes.iconButton} onClick={handleClose}><CloseIcon /></IconButton></Box> */}
                {props.children}
                </div>
            </Fade>
        </Modal>
        <AlertDialog
            open={openDialog}
            handleClose={handleDialog}
            title=""
        />
        </>
    )
};

export default CustomModal;