import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {theme} from '../theme/theme';
import {Box, Button} from '@material-ui/core';
import {MainContent} from '../components/styled';
import Layout from '../layout/common';
import CustomModal from '../components/modal';
import AddCompany from '../components/forms/addCompany';
import Company from '../components/company';
import company from './client/company';
import CompanyList from '../components/companies';
import StatusBox from '../components/statusBox';
import FilterList from '../components/forms/filterList';

const useStyles = makeStyles({
    root: {
        margin: theme.spacing(0),
    },
    button: {
        padding: '12px 24px',
    },
    statusBox: {
        width: '400px',
    },
    
})

const sortingList = [
    {
        label: 'last updated',
        value: '0',
    },
    {
        label: 'A to Z',
        value: '1',
    },
    {
        label: 'Z to A',
        value: '2',
    },
]

const filterList = [
    {
        label: 'All',
        value: '0',
    },
    {
        label: 'Active',
        value: '1',
    },
    {
        label: 'Disabled',
        value: '2',
    }
]

const Companies = (props) => {
    const classes = useStyles();
    const token = props.token;
    const [filter, setFilter] = useState('0');
    const [sorting, setSorting] = useState('0');
    const [openForm, setOpenForm] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [companyCount, setCompanyCount] = useState({
      total: 0,
      active: 0,
      disabled: 0,
  });

    const handleOpen = () => {
        setOpenForm(true)
    };
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
      setOpenForm(false);
      setIsChanged(false)
      setRefresh(true)
    };
    const changeFormStatus = (status) => {
        setIsChanged(status)
    };
    const LoadData = () => {
        console.log('load')
        return (
            <CompanyList token={token} setRefresh={setRefresh} refresh={refresh} filter={filter} sorting={sorting} />
        )
    }
    useEffect(()=>{
        LoadData()
        company.getList(token).then(({data}) => {
        //    console.log(data.data.length)
          countCompany(data.data)
          // console.log(company);
        }).catch(console.error);
    },[])

    useEffect(() =>{
        if (refresh){
            LoadData()
            company.getList(token).then(({data}) => {
                countCompany(data.data)
            }).catch(console.error);
            // window.location.reload();
            setRefresh(false)
        }
    },[refresh])

    const countCompany = (data) => {
        const active = data.filter((x)=> x.status === 'active')
        const disabled = data.filter((y)=> y.status === 'disabled')
        setCompanyCount({total: data.length, active: active.length, disabled: disabled.length})
    }
    const handleSort = (data) => {
        //sorting algorithm
        setSorting(data);
    }

    const handleFilter = (data) => {
        //sorting algorithm
        setFilter(data);
    }

    return(
        <Layout token={props.token}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.root}>
               <h1 className={classes.header}>Companies</h1>
               <div className={classes.statusBox}>
                    <StatusBox statusValues={companyCount} data="company" />
               </div>
               <Box>
                   Filter &nbsp;
                   <FilterList
                   data={filter}
                   onchange={(e) => {handleFilter(e)}}
                   items={filterList} 
                   setRefresh={setRefresh}
                   />
                </Box>
               <Box>
                   Sort by &nbsp;
                   <FilterList
                   data={sorting}
                   onchange={(e) => {handleSort(e)}}
                   items={sortingList} 
                   setRefresh={setRefresh}
                   />
                </Box>
               <Box>
                   <Button className={classes.button} variant="contained" color="primary" onClick={handleOpen}>Add New Company</Button>
                        <CustomModal
                            open={openForm}
                            close={handleClose}
                            formStatus={isChanged}
                            title="Add New Company"
                            titleStyle="primary"
                            ariaLabelledby="add-new-company-form-title"
                            ariaDescribedby="add-new-company--form-description">
                        <AddCompany token={props.token} onchange={changeFormStatus} LoadData={LoadData} setRefresh={setRefresh} handleClose={handleClose} />
                    </CustomModal>
               </Box>
            </Box>
            {LoadData()}
        </Layout>
    )
};

export default Companies;