import React, {useState,useEffect} from 'react';
import {Box, Button, InputBase, MenuItem, Select} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {theme} from '../theme/theme';
import Layout from '../layout/common';
import company from './client/company'
import { MainContent } from '../components/styled';
import CustomModal from '../components/modal';
import UserDetails from '../components/user';
import AddUser from '../components/forms/addUser'
import UserList from '../components/users'
import StatusBox from '../components/statusBox';
import UsersClient from '../pages/client/Auth'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
}))(InputBase);

const useStyles = makeStyles({
    root: {
        margin: 0,
    },
    button: {
        padding: '12px 24px',
    },
    statusBox: {
        width: '450px',
    },
    select: {
        fontSize: '0.875rem',
        marginTop: theme.spacing(1),
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#304344',
        padding: '0 8px',
        position: 'relative',
        width: '250px',
        color: '#69949D',
        fontWeight: '500',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: '#253334',
            borderRadius: theme.shape.borderRadius,
            color: 'white',
        },
    },
    selectOptions: {
        backgroundColor: '#304344',
        borderColor: theme.palette.secondary.main,
        borderRadius: '0 0 8px 8px',
        border: 'none',
        color: theme.palette.text.muted,
        fontSize: '0.875rem',
        padding: '0',
        width: '250px',
        marginLeft: '-8px',
        marginTop: '-2px',
        boxShadow: 'none',
        "& .MuiList-padding": { paddingTop: '0px', paddingBottom: '0px'},
        "& .MuiListItem-root": {
            borderRadius: 0,
            padding: '10px 26px 10px 12px',
            width: '100%',
            border: 'none',
        },
        "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "transparent",
            color: 'white',
        },
        "select &:focus, select &:hover" : {
            border: 'none',
            color: 'white',
        }
    },
    icon: {
        color: theme.palette.text.primary,
        marginRight: '8px',
        fontSize: '1.5rem',
        verticalAlign: 'middle',
    },
    iconOpen: {
        color: theme.palette.primary.main,
    },
})

const MenuProps = {
    PaperProps: {
        style: {
        minWidth: '250px',
        fontSize: '0.875rem',
        maxHeight: '350px'
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};
const Users = (props) => {
    const classes = useStyles();
    const token = props.token;
    MenuProps.PaperProps.className = classes.selectOptions;
    const [openForm, setOpenForm] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [filter, setFilter] = useState('all')
    const [companyList, setCompanyList] = useState();
    const [refresh, setRefresh] = useState(false);
    const [userCount, setUserCount] = useState({
        total: 0,
        admin: 0,
        manager: 0,
    });
    const handleOpen = () => {
        setOpenForm(true)
    };
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
      setOpenForm(false);
      setIsChanged(false)
    };
    const changeFormStatus = (status) => {
        setIsChanged(status)
    };
    const LoadData = () => {
        return (
            <UserList token={token} companyList={companyList} setRefresh={setRefresh} refresh={refresh} filter={filter} />
        )
    }
    const handleChange = (e) => {
        setFilter(e.target.value)
        setRefresh(true)
    }
    useEffect(()=>{
        LoadData()
        UsersClient.getList(token).then(({data}) => {
            countUser(data.data)
        }).catch(console.error)
    },[])

    useEffect(()=>{
        company.getList(token).then((data)=>{
            setCompanyList(data.data.data)
            // console.log('company',data.data.data)
        }).catch((e)=>console.error(e))
    },[])

    useEffect(() =>{
        if (refresh){
            LoadData()
            UsersClient.getList(token).then(({data}) => {
                countUser(data.data)
            }).catch(console.error)
            // window.location.reload();
            setRefresh(false)
        }
    },[refresh])

    const countUser = (data) => {
        console.log(data)
        const admin = data.filter((x)=> x.role === 'admin')
        const manager = data.filter((y)=> y.role === 'manager')
        setUserCount({total: data.length, admin: admin.length, manager: manager.length})
    }

    return(
        <Layout token={token}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.root}>
               <h1 className={classes.header}>Users</h1>
               <div className={classes.statusBox}>
                    <StatusBox statusValues={userCount} data="user" />
               </div>
               <Box>
                   Filter &nbsp;
                   <Select
                    classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
                    className={classes.select}
                    value={filter}
                    onChange={(e)=>handleChange(e)}
                    input={<BootstrapInput />}
                    MenuProps={MenuProps}
                    IconComponent={ExpandMoreIcon}>
                        <MenuItem value="all">
                            All
                        </MenuItem>
                        { companyList && companyList.length > 0 ?
                        companyList.map((item, key) =>
                        <MenuItem value={item._id} key={key}>
                            {item.name}
                        </MenuItem>
                        )
                    :null}
                </Select>
                </Box>
               <Box>
                   <Button className={classes.button} variant="contained" color="primary" onClick={handleOpen}>Add New User</Button>
                        <CustomModal
                            open={openForm}
                            close={handleClose}
                            formStatus={isChanged}
                            title="Add New Users"
                            titleStyle="primary"
                            ariaLabelledby="add-new-company-form-title"
                            ariaDescribedby="add-new-company--form-description">
                        <AddUser token={props.token} companyList={companyList} onchange={changeFormStatus} LoadData={LoadData} setRefresh={setRefresh} handleClose={handleClose} page="users" />
                    </CustomModal>
               </Box>
            </Box>
            {LoadData()}
            {/* <MainContent>
                <UserDetails data={SampleData} companyList={companyList} />
            </MainContent> */}
        </Layout>
    )

};

export default Users;