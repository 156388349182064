import React, {useState} from 'react'
import { Redirect } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from '../theme/theme';
import { Container, Box, AppBar } from '@material-ui/core';
import Header from '../components/header';
import Sidebar from '../components/sidebar';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '12px 0'
    },
    grow: {
        flexGrow: 1,
    },
    shrink: {
        flexShrink: 1,
    },
    container: {
        height: '100vh',
        overflow: 'hidden',
    },
    appBar: {
        background: 'linear-gradient(0deg, #313F40, #313F40), #354546',
        boxShadow: 'none',
        height: '100px',
    },
    content: {
        height: 'calc(100vh - 100px)',
        flex: 1,
        overflowY: 'auto',
        // border: '1px solid blue'
    },
    sidebarOpen: {
        width: '170px',
    },
    sidebarClose: {
        width: '70px',
    },
    sidebar: {
        height: 'calc(100vh - 100px)',
        position: 'absolute',
        left: 20,
        top: 100,
        bottom: 0,
        overflowY: 'hidden',
    }
}))

const Layout = (props) => {
    const classes = useStyles();
    const token = props.token;
    // console.log(token)
    const [sidebar, setSidebar] = useState(false)

    if (!token) {
      return ( <Redirect to='/login' />)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={classes.container}>
                <Box display="flex" flexDirection="column">
                <AppBar position="sticky" className={classes.appBar}>
                    <Header token={token} />
                </AppBar>
            {token ?
                <Box display="flex" alignItems="stretch" width={1/1}>
                    <div className={sidebar ? classes.sidebarOpen : classes.sidebarClose}>
                        <div className={classes.sidebar}><Sidebar open={sidebar} setOpen={setSidebar} /></div>
                    </div>
                    <div className={classes.content}>{props.children}</div>
                </Box>
            :null}
            </Box>
        </Container>
      </ThemeProvider>
    )
};

export default Layout;