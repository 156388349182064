import React, {useState} from 'react';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { IconButton, Link, Box } from '@material-ui/core'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Icon from '../components/icon';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { theme } from '../theme/theme'
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
const Menu = styled.div`
    display: flex;
    margin-right: 8px;
`

const MenuItems = styled.ul`
    & li {
        height: 60px;
        display: flex;
        align-items: center;
    }
    margin: 0;
    padding: 0;

    &.icons {
        width: 60px;
        justify-content: center;
    }
    &.text {
        width: 0px;
        padding: 0 8px;
        color: #5A7679;
        transition: width 1s ease-in-out;
        font-size: 13px;
        display: none;
    }
    &.show {
        display: block;
        width: 100px
    }
    & li.active, & li.active a {
        color: #24ABA3;
    }

`
const CustomLink = styled(Link)`
    text-decoration: none;
    &, &:visited {
        color: #5A7679;
    }
    &:hover {
       color: #24ABA3;
    }
`


const useStyles = makeStyles({
  root: {
    background: "#283637",
    color: '#24ABA3',
    padding: '8px',
  },
  square: {
      background: "#283637",
      color: '#24ABA3',
      borderRadius: '8px',
      width: '24px',
      height: '24px',
  },
  active: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: '8px',
      boxShadow: '2px 2px 20px rgba(36, 171, 163, 0.3)',
      width: '24px',
      height: '24px',
      '&.beacon': {
        background: theme.palette.secondary.main,
      },
      '& path': {
            fill: 'white',
        }
  },
  icon: {
      width: '22px',
      height: '22px',
      fill: '#5A7679',
  }

})

const Sidebar = (props) => {
    const classes = useStyles();
    const url = new URL(window.location.href);
    const path = url.pathname.split("/");
    const [state, setState] = useState({
        menuOpen: 'hide',
        selected: path[1],
    })

    function toggleMenu() {
        setState(
            {...state, menuOpen: state.menuOpen === 'hide' ? 'show' : 'hide'}
        )
        props.setOpen(!props.open)
    }

    function toggleSelected(tab) {
        setState(
            {...state, selected: tab}
        )
        
    }

    return (
        <Menu>
            <MenuItems className="icons">
                <li>
                    <IconButton className={classes.root} onClick={toggleMenu}>
                        {state.menuOpen === 'show' ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/" className={state.selected === '' ? classes.active : classes.square} onClick={()=>toggleSelected('')}>
                        <Icon icon="dashboard" color='#5A7679' />
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/companies" className={state.selected === 'companies' ? classes.active : classes.square} onClick={()=>toggleSelected('companies')}>
                        <Box color='#5A7679'><BusinessIcon /></Box>
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/users" className={state.selected === 'users' ? classes.active : classes.square} onClick={()=>toggleSelected('users')}>
                        <Box color='#5A7679'><PeopleIcon /></Box>
                    </IconButton>
                </li>
                {/* <li>
                    <IconButton component={RouterLink} to="/riders" className={state.selected === 'riders' || state.selected === 'rider' ? classes.active : classes.square} onClick={()=>toggleSelected('riders')}>
                        <Icon icon="biker" color='#5A7679' width={26} height={20} viewBox="0 0 26 20" />
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/bikes" className={state.selected === 'bikes' || state.selected === 'bike' ? classes.active : classes.square}  onClick={()=>toggleSelected('bikes')}>
                        <Icon icon="motorcycle" color='#5A7679' width={30} height={19} viewBox="0 0 30 19" />
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/map" className={state.selected === 'map' ? classes.active : classes.square}  onClick={()=>toggleSelected('map')}>
                        <Icon icon="map" color='#5A7679' />
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/beacon" className={state.selected === 'beacon' ? `${classes.active} beacon` : classes.square}  onClick={()=>toggleSelected('beacon')}>
                        <Icon icon="beacon" color='#5A7679' width={24} height={18} viewBox="0 0 24 18" />
                    </IconButton>
                </li>
                <li>
                    <IconButton component={RouterLink} to="/accidents" className={state.selected === 'accidents' ? `${classes.active} beacon` : classes.square}  onClick={()=>toggleSelected('accidents')}>
                        <Icon icon="accidents" color='#5A7679' width={32} height={23} viewBox="0 0 32 23" />
                    </IconButton> */}
                {/* </li> */}
                {/* <li>
                    <IconButton component={RouterLink} to="/settings" className={state.selected === 'settings' ? classes.active : classes.square}  onClick={()=>toggleSelected('settings')}>
                        <Icon icon="settings" color='#5A7679'  />
                    </IconButton>
                </li> */}
                
            </MenuItems>
            <MenuItems className={state.menuOpen === 'hide' ? 'text' : 'text show' }>
                <li>
                    <CustomLink href="#" onClick={toggleMenu}>Hide Menu</CustomLink>
                </li>
                <li className={state.selected === '' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/" onClick={()=>toggleSelected('')}>Dashboard</CustomLink>
                </li>
                <li className={state.selected === 'companies' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/companies" onClick={()=>toggleSelected('companies')}>Companies</CustomLink>
                </li>
                <li className={state.selected === 'users' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/users" onClick={()=>toggleSelected('users')}>Users</CustomLink>
                </li>
                {/* <li className={state.selected === 'riders' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/riders" onClick={()=>toggleSelected('riders')}>Riders</CustomLink>
                </li>
                <li className={state.selected === 'bikes' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/bikes" onClick={()=>toggleSelected('bikes')}>Bikes</CustomLink>
                </li>
                <li className={state.selected === 'map' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/map" onClick={()=>toggleSelected('map')}>Map</CustomLink>
                </li>
                <li className={state.selected === 'beacon' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/beacon" href="#" onClick={()=>toggleSelected('beacon')}>Beacon</CustomLink>
                </li>
                <li className={state.selected === 'accidents' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/accidents" href="#" onClick={()=>toggleSelected('accidents')}>Accidents</CustomLink>
                </li> */}
                {/* <li className={state.selected === 'settings' ? 'active' : ''}>
                    <CustomLink component={RouterLink} to="/settings" onClick={()=>toggleSelected('settings')}>Settings</CustomLink>
                </li> */}
            </MenuItems>
        </Menu>
    )
};

export default Sidebar;