import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { theme } from '../theme/theme';
import { IconButton } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    boxShadow: '5px 5px 30px 0px #0000001A',
    background: theme.palette.background.dialog,
    color: '#AABCC5',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    alignItems: 'center',
    minHeight: 56,
    fontWeight: '500',
    color: theme.palette.text.header,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: '12px',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    heading: {
        fontSize: '1rem',
        flex: 1,
    },
    iconButton: {
        background: '#28373C',
        color: theme.palette.primary.main,
        padding: '6px',
        marginRight: '4px',
    },

});

const CustomAccordion = ({data}) => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{classes: {root: classes.iconButton}}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <span className={classes.heading}>{data.title}</span>
                </AccordionSummary>
                <AccordionDetails>
                    {data.description}
                </AccordionDetails>
            </Accordion>
        </div>
    )
};

export default CustomAccordion;