import {_request,getRequest,putRequest} from './Axios'

export default {
    
    post: (FormData,token) => {
        return _request("/company","post",FormData,{
            Authorization: "Bearer " + token
         });
    },


    

    putUpdate:(FormData,token,id) => {
        return _request(`/company/${id}`,"put",FormData,{
            Authorization: "Bearer " + token
         });
    },

    getStat: (token,params) => {
        return getRequest("/company/stats",{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },

    upload: (FormData,token) => {
        return _request("/upload","post",FormData,{
            Authorization: "Bearer " + token
         });
    },

    getProfile: (token) => {
        return getRequest("/company/profile",{
            Authorization: "Bearer " + token
        }).catch((error) => {
            console.log(error);
        })
    },

    getList: (token,params) => {
        return getRequest("/company",{
            Authorization: "Bearer " + token
        },params).catch((error) => {
            console.log(error);
        })
    },

    getOne: (id,token) => {
        return getRequest(`/company/${id}`,{
            Authorization: "Bearer " + token
        });
    },

    delete:(id,token) => {
        return _request(`/company/${id}`,"delete",null,{
            Authorization: "Bearer " + token
        })
    },
}