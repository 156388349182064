import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import { Box, CircularProgress, IconButton, Button } from '@material-ui/core'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import avatarImg from '../assets/images/general-post-office.png'
import CustomAvatar from './avatar';
import {theme} from '../theme/theme'
import EditCompany from './forms/editCompany';
import DeleteCompany from './forms/deleteCompany';
import CustomModal from '../components/modal';
import moment from 'moment-timezone';

const useStyles = makeStyles({
    root:{
        background: theme.palette.background.primary,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
        '& .name':{
            marginLeft: theme.spacing(2),
            color: theme.palette.text.header,
            fontWeight: '500',
        },
    },
    homeRoot:{
        background: theme.palette.background.primary,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
        '& .name':{
            marginLeft: theme.spacing(2),
            color: theme.palette.text.header,
            fontWeight: '500',
        },
    },
    updated: {
        fontSize: '11px',
        '& .label': {
            marginRight: theme.spacing(1),
        },
        '& .date':{
            color: 'white',
        }
    },
    infoBox: {
        borderLeft: '1px solid #2E3D3F',
        paddingLeft: theme.spacing(2),
    },
    label: {
        fontSize: '0.75rem',
        fontWeight: '500',
    },
    dataBox: {
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(3),
    },
    data: {
        color: theme.palette.text.header,
        marginLeft: theme.spacing(1),
        display: 'inline-block',
        fontWeight: '500',
    },
    profileLabel: {
        color: theme.palette.text.primary,
        fontWeight: '500',
        fontSize: '14px',
        width: '120px',
    },
    icon: {
      color: '#5A7679',
    },
    disabled: {
        marginLeft: theme.spacing(2),
        '& .companyName':{
            color: theme.palette.text.header,
            fontWeight: '500',
        },
        '& .status':{
            textTransform: 'capitalize',
            fontSize: '0.875rem',
        }
    },
    company: {
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
        '& .companyName':{
            fontWeight: '500',
        },
        '& .status':{
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            fontSize: '0.875rem',
        }
    },
    weeklyReportButton: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        fontSize: theme.typography.overline.fontSize,
    }
});

const Company = (props) => {
    const classes = useStyles();
    console.log('company', props)
    const {data, token, LoadData, onchange,setRefresh, page} = props;
    const [openForm, setOpenForm] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [formtype, setFormtype] = useState(null);
    const handleOpen = (props) => {
      setFormtype(props);
      setOpenForm(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
      setOpenForm(false);
    };

    const changeFormStatus = (status) => {
        setIsChanged(status)
    }
    console.log(LoadData)
    return (
        <>
        { props.page === 'home' ? 
        <div className={classes.homeRoot}>
            <Box display="flex" alignItems="center">
                <CustomAvatar img={data.image || avatarImg} size="xsmall" />
                { data.status === 'disabled' ? 
                    <div className={classes.disabled} >
                        <span className="companyName" >{data.name}</span>
                    </div>
                :
                    <div className={classes.company} >
                        <span className="companyName" >{data.name}</span>
                    </div>
                }
            </Box>
            <Box className={classes.updated} flexGrow={1} textAlign="right" px={2}>
                <span className="label">Last Updated</span>
                <span className="date">{moment(data.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
            </Box>
            <div>
                <IconButton className={classes.icon} onClick={(e)=>handleOpen('edit')} size="small"><EditIcon fontSize="small" /></IconButton>
                <IconButton color="secondary" onClick={(e)=>handleOpen('delete')} size="small"><DeleteIcon fontSize="small" /></IconButton>
            </div>
        </div>
        :
        <div className={classes.root}>
            <Box display="flex" alignItems="center" width={1/4}>
                <CustomAvatar img={data.image} size="large" />
                { data.status === 'disabled' ? 
                    <Box className={classes.disabled}>
                        <div className="companyName">
                            {data.name}
                        </div>
                        <div className="status">
                            {data.status}
                        </div>
                    </Box>
                :
                    <Box className={classes.company} >
                        <div className="companyName" >
                            {data.name}
                        </div>
                        <div className="status">
                            {data.status}
                        </div>
                    </Box>
                }
            </Box>
            <Box className={classes.infoBox} flexGrow={1}>
                <label className={classes.label}>Contact Person</label>
                <div className={classes.dataBox}>
                    <div className={classes.profileLabel}>
                        Name
                    </div>
                    <div className={classes.data}>{data.contact_person.first_name} {data.contact_person.last_name}</div>
                </div>
                <div className={classes.dataBox}>
                    <div className={classes.profileLabel}>
                        Email
                    </div>
                    <div className={classes.data}>{data.contact_person.email}</div>
                </div>
                <div className={classes.dataBox}>
                    <div className={classes.profileLabel}>
                        Mobile No.
                    </div>
                    <div className={classes.data}>{data.contact_person.mobile_no}</div>
                </div>
            </Box>
            <Box display={'flex'} alignSelf={'stretch'} flexDirection={'column'} alignItems={'end'}>
                <Box marginY={'auto'} display={'flex'} flexWrap={'wrap'} justifyContent={'end'}>
                    <IconButton className={classes.icon} onClick={(e)=>handleOpen('edit')}><EditIcon fontSize="small" /></IconButton>
                    <IconButton color="secondary" onClick={(e)=>handleOpen('delete')}><DeleteIcon fontSize="small" /></IconButton>
                </Box>
                <Button component={'a'} href={`${process.env.REACT_APP_API_URL}/company/${data._id}/generateWeeklyReport`} className={classes.weeklyReportButton} color="primary" variant="outlined">Generate weekly report</Button>
            </Box>
        </div>
        }
        <CustomModal
                open={openForm}
                close={handleClose}
                formStatus={isChanged}
                title={formtype === 'edit' ? 'Edit Company': 'Delete Company?'}
                titleStyle={formtype === 'edit' ? "primary" : "secondary" }
                ariaLabelledby={formtype === 'edit' ? "edit-company-form-title" : "delete-company-form-title"}
                ariaDescribedby={formtype === 'edit' ? "edit-company--form-description" : "delete-company--form-description"}>
                { page === 'home' ?
                <>
                    {formtype === 'edit' ?
                        <EditCompany token={props.token} data={data} id={data._id} onchange={changeFormStatus} page="home" />
                    : formtype === 'delete'?
                        <DeleteCompany token={props.token} company={data} id={data._id} onchange={changeFormStatus} page="home" />
                    : null}
                </>
                :
                <>
                    {formtype === 'edit' ?
                        <EditCompany LoadData={LoadData} token={token} data={data} id={data._id} onchange={changeFormStatus} setRefresh={setRefresh} />
                    : formtype === 'delete'?
                        <DeleteCompany token={token} company={data} id={data._id} onchange={changeFormStatus} LoadData={LoadData} setRefresh={setRefresh} />
                    : null}
                </>
                }
            </CustomModal>
        </>
    )
};

export default Company;