import React from 'react';
import { InputBase, MenuItem, Select } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
}))(InputBase);

const useStyles = makeStyles((customTheme) => ({
    root: {
        flexGrow: 1,
        '& .MuiPaper-root': {
            backgroundColor: '#253334',
        }
    },
    select: {
        fontSize: '0.875rem',
        marginTop: theme.spacing(1),
        border: '1px solid #2D464E',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#304344',
        padding: '0 8px',
        position: 'relative',
        width: '130px',
        color: theme.palette.text.header,
        fontWeight: '500',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: '#253334',
            borderRadius: theme.shape.borderRadius,
            color: 'white',
        },
    },
    selectOptions: {
        backgroundColor: '#304344',
        borderRadius: '0 0 8px 8px',
        border: 'none',
        color: theme.palette.text.muted,
        fontSize: '0.875rem',
        padding: '0',
        width: '130px',
        marginLeft: '-9px',
        marginTop: '-2px',
        boxShadow: 'none',
        "& .MuiList-padding": { paddingTop: '0px', paddingBottom: '0px'},
        "& .MuiListItem-root": {
            borderRadius: 0,
            padding: '10px 26px 10px 12px',
            width: '100%',
            border: 'none',
        },
        "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "transparent"
        },
        "select &:focus, select &:hover" : {
            border: 'none',
        }
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: '8px',
        fontSize: '1.5rem',
        verticalAlign: 'middle',
    },
    iconOpen: {
        color: theme.palette.primary.main,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
        minWidth: '120px',
        fontSize: '0.875rem',
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};


const TimeSelect = (props) => {

    const classes = useStyles();
    MenuProps.PaperProps.className = classes.selectOptions;
    const customTheme = {theme};
    const list = props.items;

    const handleChange = (e) => {
        props.onchange(e.target.value)
    }

    return (
        <Select
            classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
            className={classes.select}
            value={props.data}
            onChange={handleChange}
            input={<BootstrapInput />}
            MenuProps={MenuProps}
            IconComponent={ExpandMoreIcon}>
                { list && list.length > 0 ?
                list.map((item, key) =>
                <MenuItem value={item.value} key={key}>
                    {item.label}
                </MenuItem>
                )
            :null}
            {/* <MenuItem value="scoring">scoring</MenuItem>
            <MenuItem value="total">total distance</MenuItem>
            <MenuItem value="notification">notifications</MenuItem> */}
        </Select>
    )
};

export default TimeSelect;