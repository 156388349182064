import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/images/logo.png';
import SearchInput from '../components/search';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button, Box, Divider,IconButton, Link} from '@material-ui/core';
import { theme } from '../theme/theme'
import avatarImg from '../assets/images/general-post-office.png'
import CustomAvatar from './avatar';
import CustomModal from './modal'
import ReportForm from './forms/reportAccidentForm';
import CompanyClient from '../pages/client/company'
import Icon from '../components/icon';
import styled from 'styled-components';
const CustomLink = styled(Link)`
    text-decoration: none;
    &, &:visited {
        color: #5A7679;
    }
    &:hover {
       color: #24ABA3;
    }
`
const useStyles = makeStyles({
  root: {
    margin: '16px 0'
  },
  button: {
    borderRadius: 8,
    padding: '12px 24px',
    marginLeft: '16px',
  },
  divider: {
    backgroundColor: '#3C5558',
    margin: '8px auto',
  },
  heading: {
    color: theme.palette.text.header,
  },
  square: {
      background: "#283637",
      color: '#24ABA3',
      borderRadius: '8px',
      width: '18px',
      height: '18px',
      margin: theme.spacing(0,1),
  },
  active: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: '8px',
      boxShadow: '2px 2px 20px rgba(36, 171, 163, 0.3)',
      width: '24px',
      height: '24px',
      '&.beacon': {
        background: theme.palette.secondary.main,
      },
      '& path': {
            fill: 'white',
        }
  },
  icon: {
      width: '22px',
      height: '22px',
      fill: '#5A7679',
  }
})

const Header = ({token}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const [company, setCompany] = useState({});
    const url = new URL(window.location.href);
    const path = url.pathname.split("/");
    const [hideButton, setHideButton] = useState(false)
    useEffect(() => {
      if (token) {
        CompanyClient.getProfile(token).then(({data}) => {
            let c = data.data.company
            setCompany({...c});
            console.log(company);
        }).catch(console.error);
      }
      if (path[1] === 'accidents') {
          setHideButton(true)
      } else {
        setHideButton(false)
      }
    },[])
    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === 'backdropClick') {
            return;
        }
      setOpen(false);
    };

    const changeFormStatus = (status) => {
        setIsChanged(status)
    }

    if (!token) {
      return (
      <Grid className={classes.root} container >
          <h3 className={classes.heading}><img src={logo} alt="Rider Dome" width="200" /> Admin Panel</h3>
      </Grid>
      )
    }else{
      return (
        <Grid className={classes.root} container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h3 className={classes.heading}><img src={logo} alt="Rider Dome" width="200" /> Admin Panel</h3>
            </Grid>
            <Grid item flexgrow={1}>
                Hello, Admin!
                <IconButton component={RouterLink} to="/admin/logout" className={classes.square}>
                    <Icon icon="logout" color='#955959' width={24} height={18} viewBox="0 0 24 18" />
                </IconButton>
            </Grid>
        </Grid>
    )
    }
}

export default Header;