import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';

const useStyles = makeStyles({
    root: {
        margin: theme.spacing(3),
        borderRadius: '10px',
        background: theme.palette.background.dark,
        padding: theme.spacing(4),
        color: theme.palette.text.header,
        '& .header': {
            ...theme.typography.h3,
            fontWeight: '500',
            marginBottom: theme.spacing(3),
            '& > .red': {
                color: theme.palette.secondary.main,
            }
        },
        '& .highlight': {
            ...theme.typography.h1,
            color: theme.palette.secondary.main,
            lineHeight: '2em',
        }
    }
});

const EmergencyContacts = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className="header">
                <span className="red">Emergency</span> numbers
            </div>
            <div>
                <span className="highlight">999</span> Police
            </div>
            <div>
                <span className="highlight">995</span> Ambulance or Fire
            </div>
            <div>
                <span className="highlight">1777</span> Non-emergency Ambulance
            </div>
        </div>
    )
};

export default EmergencyContacts;