import React, {useState} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './client/Auth';
import {notify} from './client/Toast';

//import for frontend
import { Box, Button } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { theme } from '../theme/theme'
import AlertMessage from '../components/forms/message';

const useStyles = makeStyles({
    note: {
        color: theme.palette.text.light,
        fontWeight: '400',
        fontSize: '0.875em',
    },
    inputWrap: {
        marginBottom: theme.spacing(5),
    },
    input: {
        margin: theme.spacing(0),
        marginBottom: theme.spacing(0),
        flex: 1,
        color: theme.palette.text.primary,
        background: theme.palette.background.input,
        border: 'none',
        width: '375px',
        padding: '5px 12px',
        borderRadius: '5px',
        fontSize: '1em',
        '& input:-webkit-input-placeholder, & input:-internal-autofill-selected': {
            color: theme.palette.text.light,
            backgroundColor: `${theme.palette.background.input} !important` ,
        },
        '&:focus': {
            background: theme.palette.background.input,
        },
        '& div, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active, &:-internal-autofill-selected': {
            transition: 'background-color 5000s ease-in-out 0s',
            backgroundColor: theme.palette.background.input,
            color: theme.palette.text.primary,
        }
    },
    label: {
        color: theme.palette.text.light,
        display: 'block',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        fontWeight: 'normal'
    },
    button: {
        padding: '12px 40px'
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
});

const defaultValues = {
    email: '',
}
const defaultMessage = {
    title: null,
    content: null,
    type: null, //green for success, red for error
    open: false,
}
function ForgetPassword(props) {
    const classes = useStyles();
    const { handleSubmit, control, formState: { errors }, register, watch } = useForm(defaultValues);
    const [message, setMessage] = useState({
        ...defaultMessage
    });

    const handleClose = () => {
        // console.log("click handleclose")
        setMessage({
            ...defaultMessage
        });
        props.handleClose();
    };

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const checkEmail = async (email) => {
        if (validateEmail(email)) {
            try {
                let data = await Auth.checkEmail({email});
                if (data.status == "success") {
                    return false
                } else {
                    return true
                }            
            } catch(error) {
                console.log(error.message);
                return true
            }
        } else {
            return true
        }
    }

    const onFormSubmit = data => {
        const {email} = data
        Auth.forgotPassword({email}).then((data) => {
            setMessage({title: 'Successful!', content:`Password reset link is sent to ${email}`, type:'green',open:true});
            // notify(`password reset link is sent to ${email}`)
        }).catch((e) => setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red',open:true}) );
    }

    console.log(props)
    return (
        <Box>
            <p className={classes.note}>
                Enter your registered email below to receive password reset instructions
            </p>
            <form onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
                <div className={classes.inputWrap}>
                <label className={classes.label}>Email</label>
                <Controller 
                    control={control}
                    name="email"
                    rules={{
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        validate:async (a) => await checkEmail(a)
                    }}
                    render={({ field }) =>
                    <InputBase
                        className={classes.input}
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        {...field}
                        />}
                />
                <div className={classes.error}>
                    {errors.email?.type === 'required' && "*Required"}
                    {errors.email?.type === 'pattern' && "Invalid email address"}
                    {errors.email?.type === 'validate' && "Email does not existed"}
                </div>
                </div>
                <Button type="submit" className={classes.button} color="primary" variant="contained">Submit</Button>
                <AlertMessage
                    open={message.open}
                    close={handleClose}
                    title={message.title}
                    titleStyle={message.type}
                    ariaLabelledby="register-message"
                    ariaDescribedby="register-content">
                        <Box textAlign="center">
                            {message.content}
                            <Box p={3}>
                                <Button variant="contained" color="primary" className={classes.button} onClick={handleClose}>OK</Button>
                            </Box>
                        </Box>
                </AlertMessage>
            </form>
        </Box>
    )
}

export default ForgetPassword
