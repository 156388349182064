import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Modal, Backdrop, Fade, IconButton, Box } from '@material-ui/core';
import { theme } from '../../theme/theme'
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        fontSize: '2rem',
        fontWeight: '500',
        textAlign: 'center',
        '& .divider':{
            height: '1px',
            width: '90%',
            marginTop: theme.spacing(2),
        },
        '& .red': {
            color: theme.palette.secondary.main,
            '& > .divider': {
                background: `linear-gradient(98.06deg, rgba(24, 32, 33, 0) 15%, ${theme.palette.secondary.main} 100%)`,
            },
        },
        '& .green': {
            color: theme.palette.primary.main,
            '& > .divider': {
                background: `linear-gradient(98.06deg, rgba(24, 32, 33, 0) 15%, ${theme.palette.primary.main} 100%)`,
            },
        },
        
    },
    paper: {
        backgroundColor: theme.palette.background.primary,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 6),
        width: '500px',
        height: '250px',
    },
    iconButton: {
        background: "#283637",
        color: '#6F8A8F',
        padding: '8px',
        marginTop: '16px',
        '&:hover':{
            background: theme.palette.background.secondary,
        }
    },
    alignRight: {
        textAlign: 'end',
    },
    content: {
        fontSize: '12px',
        color: '#AABCC5',
    }
});

const AlertMessage = (props) => {
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby={props.ariaLabelledby}
            aria-describedby={props.ariaDescribedby}
            className={classes.modal}
            open={props.open}
            onClose={props.close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout:500,
            }}
            >
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton className={classes.iconButton} onClick={props.close}><CloseIcon /></IconButton>
                    </Box>
                    <h1 className={classes.header}>
                        <span className={props.titleStyle}>
                            {props.title}
                        <div className="divider" />
                        </span>
                    </h1>
                    <Box display="flex" justifyContent="center" className={classes.content}>
                        {props.children}
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
};

export default AlertMessage;