import React from 'react';
import CustomAccordion from '../accordion';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';

const useStyles = makeStyles({
    root: {
        width: '60%',
        margin: theme.spacing(2),
    }
})

const Faqs = ({FAQ}) => {

    const classes = useStyles();

    // const sampleData = [
    //     {
    //         title: 'Libero mollis nascetur tellus.',
    //         content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed amet nec amet volutpat potenti id purus vestibulum. Maecenas varius tempor felis scelerisque. Facilisis fames id scelerisque eleifend. Nibh et purus porttitor cursus. Orci tellus morbi eget mauris, lobortis adipiscing. Velit vitae rhoncus sit suscipit tincidunt eu, donec commodo. Viverra non vestibulum ac.',
    //     },
    //     {
    //         title: 'Lorem ipsum dolor sit amet',
    //         content: 'consectetur adipiscing elit. Sed amet nec amet volutpat potenti id purus vestibulum. Maecenas varius tempor felis scelerisque. Facilisis fames id scelerisque eleifend. Nibh et purus porttitor cursus. Orci tellus morbi eget mauris, lobortis adipiscing. Velit vitae rhoncus sit suscipit tincidunt eu, donec commodo. Viverra non vestibulum ac.',
    //     },
    //     {
    //         title: 'Sed amet nec amet volutpat potenti',
    //         content: 'Maecenas varius tempor felis scelerisque. Facilisis fames id scelerisque eleifend. Nibh et purus porttitor cursus. Orci tellus morbi eget mauris, lobortis adipiscing. Velit vitae rhoncus sit suscipit tincidunt eu, donec commodo. Viverra non vestibulum ac.',
    //     },
    // ]

    return(
        <div className={classes.root}>
            {FAQ.map((item, key) => 
                <CustomAccordion data={item} key={key} />
            )}
        </div>
    )

};

export default Faqs;