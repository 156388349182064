// form options for bike
export const engine = [
        {
            label: "Electric",
            value: "electric",
        },
        {
            label: "Fuel",
            value: "fuel",
        }
    ]

// form options for rider
export const bloodTypes = [
    'AB+', 'AB-', 'A+', 'A-', 'B+', 'B-', 'O+', 'O-'
]

//form options for accidents
export const AccidentTypes= [
    {
        label: "Lorem Ipsum 1",
        value: 'li1',
    },
    {
        label: "Lorem Ipsum 2",
        value: 'li2',
    },
]

export const userRoles = [
    {
        label: "Admin",
        value: 'admin',
    },
    {
        label: "Manager",
        value: 'manager',
    },
]
