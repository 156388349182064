import React,{useState,useEffect} from 'react'
import Auth from './client/Auth'
import companyClient from './client/company';
import { ToastContainer, toast } from 'react-toastify';
import {notify} from './client/Toast'
import {useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import { useForm, Controller } from 'react-hook-form';
import { theme } from '../theme/theme';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// imports for frontend
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, IconButton, Link } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AlertMessage from '../components/forms/message';


import LoginPageLayout from '../layout/users';


const useStyles = makeStyles({
        inputWrap: {
            marginBottom: theme.spacing(3),
        },
        input: {
            margin: theme.spacing(0),
            marginBottom: theme.spacing(1),
            flex: 1,
            color: 'white',
            background: '#28353D',
            border: 'none',
            width: '300px',
            padding: '5px 12px',
            borderRadius: '5px',
            fontSize: '1em',
            '&::-webkit-input-placeholder': {
                color: '#4C5F69',
            }
        },
        label: {
            color: '#4C5F69',
            display: 'block',
            margin: '8px 16px',
            fontWeight: 'normal'
        },
        button: {
            padding: '12px 40px'
        },
        header: {
            fontSize: '1.75em',
            fontWeight: '400'
        },
        error: {
            color: theme.palette.error.main,
            fontSize: '12px',
        },
    });

const defaultValues = {
    username: '',
    email: '',
    password: '',
}

const defaultMessage = {
    title: null,
    content: null,
    type: null, //green for success, red for error
    open: false,
}
function Register(props) {



    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
      
    const checkEmail = async (email) => {
        if (validateEmail(email)) {
            try {
                let data = await Auth.checkEmail({email});
                if (data.status == "success") {
                    return true
                } else {
                    return false
                }            
            } catch(error) {
                return false
            }
            
        } else {
            return true
        }
    }

    const classes = useStyles()
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['token']);
    const [company_list, setCompanyList] = useState([]);
    const { handleSubmit, control, formState: { errors }, watch } = useForm(defaultValues);
    const [message, setMessage] = useState({
        ...defaultMessage
    });

    useEffect(() => {
        companyClient.getList().then(({data}) => {
            setCompanyList(data.data);
        }).catch(console.error);
    }, [])

    const handleClose = () => {
        // console.log("click handleclose")
        setMessage({
            ...defaultMessage
        });
    };
    const onFormSubmit = data => {
        // console.log('submit',data)
        // e.preventDefault();
        // console.log(props.history);
        const {email,password,username} = data
         Auth.register({email,password,username}).then(({token}) => {
            setCookie('superadmintoken', token, { path: '/' });
            console.log("set cookie");
            // notify("register successfully","success");
            setMessage({title: 'Successful!', content:'Registered successfully! Please login', type:'green',open:true});
            setTimeout(() => { 
                history.push("/login");
            }, 3000);
        }).catch((e) =>  {
            setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red',open:true})
            // notify(e.message,"error")
        });
    }
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    // console.log(watch(),errors)

    return (
        <LoginPageLayout token={props.token}>
            <Box p={4} m={4}>
                <h1 className={classes.header}>Create New Account</h1>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className={classes.inputWrap}>
                        <label className={classes.label}>Username</label>
                        <Controller 
                            control={control}
                            name="username"
                            rules={{required: true}}
                            render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="username"
                                type="text"
                                placeholder="Alex"
                                {...field}
                                />}
                        />
                        <div className={classes.error}>
                            {errors.username?.type === 'required' && "*Required"}
                        </div>
                    </div>
                    <div className={classes.inputWrap}>
                        <label className={classes.label}>User Email</label>
                        <Controller 
                            control={control}
                            name="email"
                            rules={{
                                required: true, 
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                validate:async (a) => await checkEmail(a)
                            }}
                            render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="email"
                                type="text"
                                placeholder="abc@gmail.com"
                                {...field}
                                />}
                            />
                        <div className={classes.error}>
                            {errors.email?.type === 'required' && "*Required"}
                            {errors.email?.type === 'pattern' && "Invalid email address"}
                            {errors.email?.type === 'validate' && "Email already existed"}
                        </div>
                    </div>
                    <div className={classes.inputWrap}>
                        <label className={classes.label}>Company</label>
                            <Autocomplete
                                id="combo-box-demo"
                                options={company_list}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  />}
                            />
                        {/* <InputBase
                                className={classes.input}
                                id="password"
                                placeholder="Password"
                        
                        /> */}
                    </div>    
                    <div className={classes.inputWrap}>
                        <label className={classes.label}>Password</label>
                        <Controller 
                            control={control}
                            name="password"
                            rules={{
                                required: true,
                                minLength: 8
                            }}
                            render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                {...field}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />}
                            />
                        <div className={classes.error}>
                            {errors.password?.type === 'required' && "*Required"}
                            {errors.password?.type === 'minLength' && "At least 8 characters"}
                        </div>
                    </div>
                <Box style={{margin: '3em 0'}}>
                    <Button type="submit" className={classes.button} variant="contained" color="primary">Register</Button>
                </Box>
                <AlertMessage
                    open={message.open}
                    close={handleClose}
                    title={message.title}
                    titleStyle={message.type}
                    ariaLabelledby="register-message"
                    ariaDescribedby="register-content">
                        <Box textAlign="center">
                            {message.content}
                        </Box>
                </AlertMessage>
            </form>
        </Box>
        <Box p={4} m={4} textAlign="center">Already have an account? <Link href="/login">Log in</Link></Box>
      </LoginPageLayout>
    )
};

export default Register;
