import React, {useState,useEffect} from 'react'
import { Redirect,useParams,useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './client/Auth'
import {notify} from './client/Toast'
import { useForm, Controller } from "react-hook-form";

//imports for front end
import LoginPageLayout from '../layout/users';
import { Box, IconButton, Button, FormControl,TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../theme/theme';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AlertMessage from '../components/forms/message';

const useStyles = makeStyles({
    root: {
        color: '#FFF'
    },
    header: {
        ...theme.typography.h1,
        fontSize: '1.75em',
        fontWeight: '400'
    },
    inputWrap: {
        margin: theme.spacing(0),
        marginBottom: theme.spacing(3),
    },
    input: {
        flex: 1,
        color: 'white',
        background: '#28353D',
        border: 'none',
        width: '375px',
        padding: '5px 12px',
        borderRadius: '5px',
        fontSize: '1em',
        borderBottom: 'none',
        '&::-webkit-input-placeholder': {
            color: '#4C5F69',
        },
    },
    label: {
        color: '#4C5F69',
        display: 'block',
        margin: '8px 16px',
        fontWeight: 'normal'
    },
    button: {
        padding: '12px 40px'
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    }
});


const defaultMessage = {
    title: null,
    content: null,
    type: null, //green for success, red for error
    open: false,
}

function ResetPassword() {
    const classes = useStyles();
    let {slug} = useParams();
    const { handleSubmit, control, formState: { errors }, watch, setError, getValues } = useForm();
    const [email, setEmail] = useState('')
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false)
    const [message, setMessage] = useState({
        ...defaultMessage
    });

    const handleClose = () => {
        // console.log("click handleclose")
        setMessage({
            ...defaultMessage
        });
    };

    const comparePassword = (password1, password2) => {
        if (password1 !== '' && password2 !== '' && password1 !== password2) {
            console.log('error')
            setError('confirmpassword',{type:'notMatch', message:'Passwords do not match'})
            return false
        } else {
            // setValues({...values,password:password1})
            // updatePassword(password1)
            return true
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getEmail = () => {
        const url = new URL(window.location.href);
        const emailAdd = url.searchParams.get('email')
        setEmail(emailAdd);
    }
   
    useEffect(()=>{
        getEmail();
    },[])
    function updatePassword(data) {
        const {password} = data
        Auth.resetPassword({email,password,token:slug}).then((data) => {
            // console.log(data);
            // notify("password reset successfully","success");
            setMessage({title: 'Successful!', content:'Password changed successfully! Please login with your new password', type:'green',open:true});
            setTimeout(() => { 
                history.push("/login");
            }, 3000);
        }).catch((e) => setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red',open:true}));
    }

    const onFormSubmit = data => {
        // alert(JSON.stringify(data));
        // console.log(data)
        const {password, confirmpassword} = data;
        if (comparePassword(password, confirmpassword)) {
            updatePassword(data)
            // console.log('password same!')
        }
    };

    // console.log(watch(),email)

    return (
        <LoginPageLayout>
            <Box p={4} m={4} width={375}>
                <h1 className={classes.header}>Create new password</h1>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                {/* <form onSubmit={e => {handleSubmit(e)}} method="post"> */}
                    <div className={classes.inputWrap}>
                        <label className={classes.label}>New Password</label>
                        <Controller
                            control={control}
                            name="password"
                            rules={{required: true, minLength: 8}}
                            render={({ field }) => <InputBase
                            className={classes.input}
                            id="password" 
                            type={showPassword ? 'text' : 'password'} 
                            // onChange={handleChange('password')}
                            {...field}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />}
                        />
                        <span className={classes.error}>
                        {errors.password?.type === 'required' && "You must specify a password"}
                        {errors.password?.type === 'minLength' && "Password must have at least 8 characters"}
                        </span>
                    </div>
                    <div className={classes.inputWrap}>
                        <label className={classes.label}>Confirm Password</label>
                        <Controller
                            control={control}
                            name="confirmpassword"
                            rules={{required: true, minLength: 8}}
                            render={({ field }) => <InputBase
                            className={classes.input} 
                            id="confirmpassword" 
                            type={showPassword ? 'text' : 'password'} 
                            // onChange={handleChange('confirmpassword')}
                            {...field}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />}
                        
                        />
                        <span className={classes.error}>
                        {errors.confirmpassword?.type === 'required' && "Please type the password again"}
                        {errors.confirmpassword?.type === 'minLength' && "Password must have at least 8 characters"}
                        {errors.confirmpassword?.type === 'notMatch' && errors.confirmpassword.message}
                        </span>
                        </div>
                        <ToastContainer />
                        <Box style={{margin: '3em 0'}}>
                            <Button type="submit" className={classes.button} variant="contained" color="primary" >Reset Password</Button>
                        </Box>
                        <AlertMessage
                            open={message.open}
                            close={handleClose}
                            title={message.title}
                            titleStyle={message.type}
                            ariaLabelledby="reset-password-message"
                            ariaDescribedby="reset-password-content">
                            {message.content}
                        </AlertMessage>
            </form>
        </Box>
    </LoginPageLayout>
    )
}

export default ResetPassword
