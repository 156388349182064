import {theme} from '../theme/theme'
import styled from 'styled-components'

export const MainContent = styled.div`
    height: -moz-calc(100% - 125px);
    height: -webkit-calc(100% - 125px);
    height: calc(100% - 125px);
    border: 1px solid ${theme.palette.background.dark};
    overflow-y: auto;
    border-radius: 15px;
    padding: ${theme.spacing(2)}px;
`