import React, {useState, useEffect,useRef} from 'react';
import {useHistory } from "react-router-dom";
import { Button, InputBase, Input, Box, Grid, Divider } from '@material-ui/core';
import company from '../../pages/client/company'
import CustomAvatar from '../avatar';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import AlertMessage from './message';
import placeholder from '../../assets/images/placeholder.jpg'
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles({
    root: {
        maxHeight: '570px',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: '32px',
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.input,
        }
    },
    sectionLabel:{
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
    },
    input: {
        marginTop: theme.spacing(1),
        fontWeight: '500',
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.input,
        padding: '8px 12px',
        position: 'relative',
        width: '420px',
        color: theme.palette.text.header,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            opacity: '0.8',
            borderRadius: theme.shape.borderRadius,
        },
    },
    upload: {
        visibility: 'hidden',
        width: 0,
    },
    uploadLabel: {
        padding: '8px 24px',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginLeft: theme.spacing(4),
        fontWeight: '500',
        fontSize: '14px',
    },
    label: {
        display: 'block',
        margin: '6px 16px',
        fontWeight: 'normal',
    },
    button: {
        padding: '12px 40px',
    },
    pr16: {
        paddingRight: '16px',
    },
    mr16: {
        marginRight: '16px',
    },
    padLeft: {
        paddingLeft: '16px',
    },
    fields: {
        margin: theme.spacing(2),
    },
    heading: {
        ...theme.typography.h4,
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
    divider: {
        backgroundColor: theme.palette.background.input,
        margin: '8px auto',
    }
})

const phoneInputStyles = {
    backgroundColor: theme.palette.background.input,
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px `,
    border: `1px solid ${theme.palette.background.input}`,
    color: 'white',
    borderRadius: '10px',
    fontSize: '1rem',
    width: '420px',

}
const phoneButtonStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: '10px 0 0 10px',
    fontSize: '1rem',
}

const phoneDropdownStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    paddingRight: theme.spacing(2),
    fontSize: '1rem',
}

const defaultValues = {
    companyName: '',
    image: null,
    firstName:'',
    lastName:'',
    mobile: '',
    email: '',
}

const defaultMessage = {
    // title: 'Failed', content:`Please try again`, type:'red', open: true
    // title: 'Successful!', content:'New rider has been added succesfully!', type:'green',open: true
    title: null,
    content: null,
    type: null,
    open: false,
}

const AddCompany = (props) => {
    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

        return [ htmlElRef, setFocus ] 
    }

    const classes = useStyles();
    const [preview, setPreview] = useState(placeholder);
    const [inputRef, setInputFocus] = useFocus();
    const [status,setStatus] = useState(0);
    const [message, setMessage] = useState({
        ...defaultMessage
    });
    const history = useHistory();
    const LoadData = props.LoadData;
    const { handleSubmit, control, formState: { errors }, register, setError, clearErrors,reset, watch, getValues, setValue } = useForm({
        defaultValues
    });

    function onFileSelect(e) {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        // console.log(selectedFile.name)
        setPreview(URL.createObjectURL(selectedFile))
        setValue('image',selectedFile);
        props.onchange(true)
    }

    const onFormSubmit = data => {
        // e.preventDefault();
        // onSubmit(data);
        console.log('submit',data)
        const formData = new FormData();
        for ( var key in data ) {
            formData.append(key, data[key]);
        }

        formData.append('status','active')

        company.post(formData,props.token).then((data) => {
            setMessage({title: 'Successful!', content:'New company has been added succesfully!', type:'green',open: true,})
            if (props.page === 'company') 
            { LoadData(); }
            props.onchange(false);
            setStatus(1)
        }).catch((e) => {
            setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red', open: true,})
            setStatus(2)
        });
    }
    useEffect(()=> {
        setInputFocus()
        //console.log('rendered',reset)
    },[])

    const handleClose = () => {
      setMessage({
          ...defaultMessage
      });
    };
    const closeForm = () => {
        // close all modal 
        handleClose()
        props.handleClose()
        if (props.page === 'home') {
            history.push("/companies")
        } else {
            props.setRefresh(true);
        }
        // set Status back to original
        setStatus(0)
    }
    const reloadForm = () => {
        console.log('hello')
        const resetForm = async () => await reset();
        const closeModal = async () => await handleClose();
        setPreview(placeholder);
        setStatus(0)
        resetForm().then(()=>closeModal().then(()=>setInputFocus()))

    }
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container direction="row">
                <Grid item sx>
                    <div className={classes.fields}>
                        <label htmlFor="companyName" className={classes.label}>Company Name *</label>
                        <Controller
                            control={control}
                            name="companyName"
                            rules={{required: true}}
                            render={({ field }) => (
                                <InputBase
                                autoFocus
                                inputRef={inputRef}
                                className={classes.input}
                                id="companyName"
                                type="text"
                                {...field}
                                onChange={(e) => {field.onChange(e);props.onchange(true)}} 
                                placeholder="Company Name"
                                />
                            )}
                        />
                        <div className={classes.error}>
                            {errors.companyName?.type === 'required' && "*Required"}
                        </div>
                    </div>
                </Grid>
                <Grid item sx={6}>
                    <div className={classes.fields}>
                        <label className={classes.label}>Company Logo</label>
                        <Box display="flex" alignItems="center" >
                            <CustomAvatar img={preview} size="large" />
                            <Box className={classes.padLeft}>
                                <label htmlFor="image" className={classes.uploadLabel}>
                                    Upload Image
                                    <Controller
                                        name="image"
                                        control={control}
                                        // rules={{required: true}}
                                        render={({ field }) => (
                                        <Input
                                            id="image"
                                            type="file"
                                            className={classes.upload}
                                            {...field}
                                            onChange={(e) => {field.onChange(e);onFileSelect(e);}} 
                                            value={(e) => e.target.value}
                                        />)}
                                    />
                                </label>
                            </Box>
                        </Box>
                        {/* <div className={classes.error}>
                            {errors.companyName?.type === 'required' && "*Required"}
                        </div> */}
                    </div>
                </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <div className={classes.sectionLabel}>
                    Contact Person
                </div>
                <Grid container direction="row">
                    <Grid item sx={6}>
                        <div className={classes.fields}>
                            <label htmlFor="first-name" className={classes.label}>First Name</label>
                            <Controller
                                control={control}
                                name="firstName"
                                rules={{required: false}}
                                render={({ field }) => (
                                    <InputBase
                                    className={classes.input}
                                    id="firstName"
                                    type="text"
                                    {...field}
                                    onChange={(e) => {field.onChange(e);props.onchange(true)}} 
                                    placeholder="First Name"
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item sx={6}>
                        <div className={classes.fields}>
                            <label htmlFor="last-name" className={classes.label}>Last Name</label>
                            <Controller
                                control={control}
                                name="lastName"
                                rules={{required: false}}
                                render={({ field }) => (
                                    <InputBase
                                    className={classes.input}
                                    id="lastName"
                                    type="text"
                                    {...field}
                                    onChange={(e) => {field.onChange(e);props.onchange(true)}} 
                                    placeholder="Last Name"
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item sx={6}>
                    <div className={classes.fields}>
                        <label htmlFor="mobile" className={classes.label}>Mobile Number</label>
                        <Controller
                                name="mobile"
                                control={control}
                                render={({field}) => {
                                return (
                                    <PhoneInput
                                        {...field}
                                        placeholder="+65 9123 4567"
                                        inputProps={{
                                            name: 'mobile',
                                        }}
                                        country={'sg'}
                                        onChange={(e)=>{field.onChange(e);props.onchange(true)}}
                                        containerStyle={{marginTop: '12px'}}
                                        inputStyle={phoneInputStyles}
                                        buttonStyle={phoneButtonStyles}
                                        dropdownStyle={phoneDropdownStyles}
                                        value={field.value}
                                    />
                                );
                                }}
                            />
                        </div>
                    </Grid>
                <Grid item sx={6}>
                <div className={classes.fields}>
                    <label htmlFor="email" className={classes.label}>Email</label>
                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: false,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        }}
                        render={({ field }) =>
                            <InputBase
                                className={classes.input}
                                id="email"
                                type="text"
                                placeholder="john@doe.com"
                                {...field}
                                onChange={(e)=>{field.onChange(e);props.onchange(true)}}
                                />}
                            />
                        <div className={classes.error}>
                            {errors.email?.type === 'pattern' && "Invalid Email Address"}
                        </div>
                </div>
                </Grid>
                </Grid>
                <div className={classes.fields}>
                    <Button type="submit" className={classes.button} variant="contained" color="primary">Save</Button>
                </div>
            </form>
            <AlertMessage
                open={message.open}
                close={handleClose}
                title={message.title}
                titleStyle={message.type}
                ariaLabelledby="add-new-company-message"
                ariaDescribedby="add-new-company-message">
                    <Box textAlign="center">
                        {message.content}
                {status === 1 ? // success
                    <Box mt={4}>
                    <Button variant="contained" color="primary" className={classes.mr16} onClick={reloadForm}>Add Another Company</Button>
                    <Button variant="outlined" color="primary" onClick={closeForm}>Close</Button>
                    </Box>
                : status === 2 ?
                    <Box mt={4}>
                    <Button variant="contained" color="primary" onClick={handleClose}>OK</Button>
                    </Box>
                : null}
                </Box>
            </AlertMessage>
        </div>
    )
};

export default AddCompany;