import React, { useState,useEffect } from 'react';
import { Button, InputBase, Input, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import CustomSwitch from '../switch';
import CustomPopover from '../popover';
import TimeSelect from './timeSelect';
import CompanyClient from '../../pages/client/company'
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles({
    root:{
        width: '50%',
        paddingBottom: '150px',
    },
    label:{
        width: '70%',
        '& label':{
            color: theme.palette.text.header,
        }
    },
    fields: {
        margin: `${theme.spacing(1)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputfields: {
        margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    },
    helperText: {
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px`,
        fontSize: '12px',
        width: '70%',
    },
    filterWrap: {
        margin: `0 ${theme.spacing(1)}px`,
    },
    input: {
        marginTop: theme.spacing(1),
        fontWeight: '500',
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.input,
        padding: '8px 12px',
        position: 'relative',
        width: '420px',
        color: theme.palette.text.header,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            opacity: '0.8',
            borderRadius: theme.shape.borderRadius,
        },
    },
    inputlabel: {
        display: 'block',
        color: '#4C5F69',
        margin: '6px 16px',
        fontWeight: 'normal',
    },
    button: {
        padding: '12px 40px',
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
});


// dummy selection values, to be provided by client
const menuList = [
    {
        label: '1 minute',
        value: 60,
    },
    {
        label: '30 seconds',
        value: 30,
    },
    {
        label: '10 seconds',
        value: 10,
    }
]

const phoneInputStyles = {
    backgroundColor: theme.palette.background.input,
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px `,
    border: `1px solid ${theme.palette.background.input}`,
    color: 'white',
    borderRadius: '10px',
    fontSize: '1rem',
    width: '420px',

}
const phoneButtonStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: '10px 0 0 10px',
    fontSize: '1rem',
}

const phoneDropdownStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    paddingRight: theme.spacing(2),
    fontSize: '1rem',
}

const Notifications = ({User,putSwitch,notiSubmit}) => {
    const classes = useStyles();

    const defaultValues = {
        email:User.contact_person ? User.contact_person.email : "",
        tel:User.contact_person ? User.contact_person.mobile_no: ""
    }
    const { handleSubmit, control, formState: { errors }, register, setError, clearErrors,reset, watch, getValues, setValue } = useForm(defaultValues);

    useEffect(() => {
        menuList.map(({value}) => {
            if (value == User.beacon_notification_delay) {
                setTimelist(value);
            }
        })

        if (User && User.contact_person) {
            setValue('email', User.contact_person.email)
            setValue('tel', User.contact_person.mobile_no)
        }

        
        
        // console.log(contact_person)
    },[])
    const [timelist, setTimelist] = useState(30);
    const handleTimelist = (data) => {

        let query = {_id:User._id};
        let update = {beacon_notification_delay:data};
        putSwitch({query,update});
        setTimelist(data)
    }

    const onFormSubmit = data => {
        // e.preventDefault();
        let {tel:mobile_no,email} = data; 
        let contact_person = {mobile_no,email}
        console.log(data)
        notiSubmit({contact_person})
        // CompanyClient.putUpdate({contact_person},token,id).then(console.log).catch(console.error);
    }
    
    console.log(watch(),errors)
    return(
        <div className={classes.root}>
            <div className={classes.fields}>
                <div className={classes.label}>
                    <label>Beacon Notification</label>
                </div>
                <CustomSwitch value={User.beacon_notification_switch} name="beacon_notification_switch" id={User._id} putSwitch={putSwitch}/>
            </div>
            {User.beacon_notification_switch ?
                <div className={classes.helperText}>
                    Send notification to rider asking him to push big 'green button' if all is ok.
                    <p>If no answer after
                        <span className={classes.filterWrap}>
                            <TimeSelect
                                data={timelist}
                                onchange={(e) => {handleTimelist(e)}}
                                items={menuList}
                            />
                        </span>
                        the system trigger alert (SMS, email, WhatsApp, or other) to a designated number with the biker information and location. </p>
                </div> : null
            }
            <div className={classes.fields}>
                <div className={classes.label}>
                    <label>Emergency Beacon</label>
                </div>
                <CustomSwitch value={User.emergency_beacon_switch} name="emergency_beacon_switch" id={User._id} putSwitch={putSwitch}/>
            </div>
            {User.emergency_beacon_switch ? 
                <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={classes.inputfields}>
                    <label htmlFor="email" className={classes.inputlabel}>Email</label>
                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: true, 
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        }}
                        render={({ field }) => 
                        <InputBase
                            value={getValues('email')}
                            className={classes.input}
                            id="email"
                            type="text"
                            placeholder="john@doe.com"
                            {...field}
                            />}
                        />
                    <div className={classes.error}>
                        {errors.email?.type === 'required' && "Required"}
                        {errors.email?.type === 'pattern' && "Invalid Email Address"}
                    </div>
                </div>
                <div className={classes.inputfields}>
                    <label htmlFor="tel" className={classes.inputlabel}>Telephone</label>
                    <Controller
                        name="tel"
                        control={control}
                        rules={{required: true}}
                        defaultValue=""
                        value={getValues('tel')}
                        render={({field}) => {
                        return (
                            <PhoneInput
                                {...field}
                                placeholder="+65 9123 4567"
                                inputProps={{
                                    name: 'tel',
                                }}
                                country={'sg'}
                                onChange={(e)=>{field.onChange(e);}}
                                containerStyle={{marginTop: '12px'}}
                                inputStyle={phoneInputStyles}
                                buttonStyle={phoneButtonStyles}
                                dropdownStyle={phoneDropdownStyles}
                                value={field.value}
                            />
                        );
                        }}
                    />
                    <div className={classes.error}>
                        {errors.tel?.type === 'required' && "Required"}
                    </div>
                </div>
                <div className={classes.inputfields}>
                    <Button type="submit" className={classes.button} variant="contained" color="primary">Save</Button>
                </div>
            </form> : null
            }
        </div>
    )
};

export default Notifications;