import React,{useState,useEffect} from 'react';
// imports for front end
import Layout from '../layout/common';
import { theme } from '../theme/theme';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Tabs, Tab, Box, Button } from '@material-ui/core';
// import SettingTabs from '../components/settings/tabs';
import CompanyClient from './client/company'
import UserClient from './client/Auth'
import AlertMessage from '../components/forms/message';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Icon from '../components/icon';
import CompanyProfile from '../components/settings/company';
import Notifications from '../components/settings/notifications';
import Faqs from '../components/settings/faqs';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const IndicatorProps = {
    style: {
        height:'0',
    }
};
const useStyles = makeStyles({
    root: {
        height: '-moz-calc(100vh - 198px)',
        height: '-webkit-calc(100vh - 198px)',
        height: 'calc(100vh - 198px)',
        overflow: 'auto',
        paddingBottom: theme.spacing(2),
    },
    header: {
        ...theme.typography.h1,
        color: theme.palette.text.header,
        marginLeft: theme.spacing(4),
    },
    tabroot: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'stretch',
        fontSize: '1rem',
        // height: '100%',
        minHeight: '100vh',
    },
    tabsWrap: {
        backgroundColor: theme.palette.background.dark,
        borderRadius: '10px',
        marginRight: theme.spacing(2),
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px `,
    },
    tabs: {
        lineHeight: '20px',
        padding: 0,
    },
    wrapper: {
        flexDirection: 'row',
        padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
        justifyContent: 'space-between',
        width: '300px',
        '& > *:first-child':{
            marginRight: '8px',
        }
    },
    tabButton: {
        borderRadius: '10px',
        color: '#6F8A8F',
        fontSize: '16px',
        margin: `${theme.spacing(1)}px 0`,
    },
    selected: {
        background: theme.palette.primary.main,
        borderRight: 'none',
        color: theme.palette.text.header,
        fontWeight: '500',
        '& svg > path':{
            fill: theme.palette.primary.contrastText,
        }
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    tabPanel: {
        backgroundColor: theme.palette.background.primary,
        borderRadius: '10px',
        flex: 1,
        // height: '100%',
    },
    button: {
        padding: '12px 40px'
    },
});

const defaultMessage = {
    title: null,
    content: null,
    type: null,
    open: false,
}

const defaultComp = {
    "beacon_notification_switch": true,
    "beacon_notification_delay": 5,
    "emergency_beacon_switch": true,
    "name":""
}

const defaultContact = {
    "email": "contact@riderdome.co",
    "mobile_no": "1212341234",
    "first_name": "Alex",
    "last_name": "Smith"
}
const Settings = ({token,cookie}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
  
    const handleChange = (e,newValue) => {
        setValue(newValue);
    };
    const [message, setMessage] = useState({
        ...defaultMessage
    });
    const [Company,setCompany] = useState({
        ...defaultComp
    });

    const [FAQ, setFAQ] = useState([]);

    const [contact_person,setContactPerson] = useState({
        ...defaultContact
    });


    const putSwitch = ({update,query}) => {

        console.log({update,query})
        UserClient.putUpdate(update,token,query._id).then(()=>getCompanyProfile()).catch(console.error);
    }

    const notiSubmit = (data) => {
        console.log(data);
        let user_id = (cookie.userdata) ? cookie.userdata.user._id : null 

        if (user_id) {
            UserClient.putUpdate(data,token,cookie.userdata.user._id).then((data)=>{
                console.log(data)
                setMessage({title: 'Successful!', content:'Emergency Beacon contact updated succesfully!', type:'green', open: true});
            }).catch((e)=>{setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red', open: true,})});
        }
        
    }

    const onSubmit = (data) => {
   
        let formData = new FormData();
        for ( var key in data ) {
            formData.append(key, data[key]);
        }
   
        CompanyClient.putUpdate(formData,token,Company._id).then((data)=>{
            setMessage({title: 'Successful!', content:'Company Profile updated succesfully!', type:'green', open: true});
            setTimeout(function(){
                window.location.href = window.location.href;
            }, 1500)
        }).catch((e)=>{setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red', open: true,})});
    }

    const handleClose = () => {
      setMessage({
          ...defaultMessage
      });
    };
    const [User,setUser] = useState({});
    useEffect(() => {
        getCompanyProfile();
        console.log('get', Company, contact_person)
    },[])
    const getCompanyProfile = () => {
        CompanyClient.getProfile(token).then(({data}) => {
            console.log('settings',data)
            let {
                company: {
                    _id,
                    image,
                    faq,
                    beacon_notification_switch,
                    beacon_notification_delay,
                    emergency_beacon_switch,
                    contact_person:ContactPerson,
                    name
                },
                user
        } = data.data;

        setContactPerson({...ContactPerson})
        setCompany({
                _id,
                image,
                beacon_notification_switch,
                beacon_notification_delay,
                emergency_beacon_switch,
                name
            });
        setUser({...user});
        setFAQ(faq);

        }).catch(console.error)
    }
    

    return (
        <Layout token={token}>
            <h1 className={classes.header}>
                Settings
            </h1>
            <div className={classes.root}>
                <div className={classes.tabroot}>
                    <div className={classes.tabsWrap}>
                        <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Settings Tabs"
                        className={classes.tabs}
                        TabIndicatorProps={IndicatorProps}
                        >
                        <Tab 
                            classes={{root: classes.tabButton, wrapper: classes.wrapper, selected: classes.selected, labelIcon: classes.iconLabel}} 
                            label={
                                <React.Fragment>
                                    <span>
                                        <Icon icon="profile" width={22} height={22} color="#24ABA3" className={classes.icon} />
                                        Profile Company
                                    </span>
                                    <ChevronRightRoundedIcon />
                                </React.Fragment>
                            }
                            {...a11yProps(0)}    
                        />
                        <Tab classes={{root: classes.tabButton, wrapper: classes.wrapper, selected: classes.selected}} 
                            label={
                                <React.Fragment>
                                    <span>
                                        <Icon icon="notification" width={24} height={24} color="#24ABA3" className={classes.icon} />
                                        Notifications
                                    </span>
                                    <ChevronRightRoundedIcon />
                                </React.Fragment>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab classes={{root: classes.tabButton, wrapper: classes.wrapper, selected: classes.selected}} 
                            label={
                                <React.Fragment>
                                    <span>
                                        <Icon icon="help" width={22} height={22} color="#24ABA3" className={classes.icon} />
                                        Help
                                    </span>
                                    <ChevronRightRoundedIcon />
                                </React.Fragment>
                            }
                            {...a11yProps(0)}
                        />
                    </Tabs>
                    </div>
                    <TabPanel value={value} index={0} className={classes.tabPanel}>
                        <CompanyProfile Company={Company} Contact={contact_person} onSubmit={onSubmit} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.tabPanel}>
                        <Notifications  notiSubmit={notiSubmit} User={User} putSwitch={putSwitch} />
                    </TabPanel>
                    <TabPanel value={value} index={2} className={classes.tabPanel}>
                        <Faqs FAQ={FAQ} />
                    </TabPanel>
                </div>
            </div>
            <AlertMessage
                open={message.open}
                close={handleClose}
                title={message.title}
                titleStyle={message.type}
                ariaLabelledby="edit-profile-message"
                ariaDescribedby="edit-profile-message">
                <Box textAlign="center">
                    {message.content}
                    <Box p={3}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleClose}>OK</Button>
                    </Box>
                </Box>
            </AlertMessage>
        </Layout>
    )
};

export default Settings;