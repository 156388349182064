import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { theme } from '../theme/theme';
import { Box } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';

const OutterDiv = withStyles((theme)=>({
    root: {
        background: 'linear-gradient(135deg, rgba(36, 171, 163, 1), rgba(36, 171, 163, 0))',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1px',
        '&.active': {
            background: 'linear-gradient(135deg, rgba(226, 96, 96, 1), rgba(0, 0, 0, 0.1))',
        },
        '&.xsmall': {
            width: '24px',
            height: '24px',
        },
        '&.small': {
            width: '45px',
            height: '45px',
        },
        '&.medium': {
            width: '60px',
            height: '60px',
        },
        '&.large': {
            width: '90px',
            height: '90px',
        },
        '&.xlarge': {
            width: '123px',
            height: '123px',
        },
    }
}))(Box);

const InnerDiv = withStyles((theme)=>({
    root: {
        width: '100%',
        height: 'auto',
        background: '#182021',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1px',
    },
}))(Box);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: '1px',
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    xsmall: {
        width: '22px',
        height: '22px',
        margin: '1px',
    },
    small: {
        width: '43px',
        height: '43px',
        margin: '1px',
    },
    medium: {
        width: '52px',
        height: '52px',
        margin: '4px',
    },
    large: {
        width: '84px',
        height: '84px',
        margin: '3px',
    },
    xlarge: {
        width: '111px',
        height: '111px',
        margin: '5px',
    },
}));

const CustomAvatar = (props) => {
    const classes = useStyles();
    return (
        <OutterDiv className={ `${props.size ? props.size : 'small'} ${props.className ? props.className: ''}` }>
            <InnerDiv>
                <Avatar
                    src={props.img}
                    className={
                        props.size === 'xsmall' ? classes.xsmall :
                        props.size === 'medium' ? classes.medium :
                        props.size === 'large' ? classes.large :
                        props.size === 'xlarge' ? classes.xlarge :
                        classes.small}
                />
            </InnerDiv>
        </OutterDiv>
    )
};


export default CustomAvatar;
