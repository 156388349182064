import React,{useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import avatarImg from '../assets/images/riderdome-logo-icon.png'
import CustomAvatar from './avatar';
import {theme} from '../theme/theme'
import CustomModal from './modal';
import EditUser from './forms/editUser'
import DeleteUser from './forms/deleteUser'
import company from '../pages/client/company';
import moment from 'moment-timezone';

const useStyles = makeStyles({
    userRoot:{
        background: theme.palette.background.primary,
        padding: theme.spacing(1,2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
        '& .name':{
            marginLeft: theme.spacing(0.5),
            color: theme.palette.text.header,
            fontSize: '14px',
        },
    },
    homeRoot:{
        background: theme.palette.background.primary,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
        '& .name':{
            marginLeft: theme.spacing(2),
            color: theme.palette.text.header,
            fontWeight: '500',
        },
    },
    iconButton: {
        background: 'linear-gradient(90.3deg, rgba(82, 102, 114, 0.5) 1.77%, rgba(33, 57, 62, 0.5) 100.91%)',
        transition: 'background 150ms cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
            background: 'linear-gradient(270deg, rgba(82, 102, 114, 0.5) 1.77%, rgba(33, 57, 62, 0.5) 100.91%)',
        }
    },
    infoBox: {
        borderRight: '1px solid #2E3D3F',
    },

    label: {
        fontSize: '0.75rem',
        fontWeight: '500',
    },
    dataBox: {
        marginRight: theme.spacing(2),
        padding: theme.spacing(0.5,0,0.5,2)
    },
    data: {
        color: theme.palette.text.header,
        // marginLeft: theme.spacing(1),
        display: 'inline-block',
        fontSize: '14px',
    },
    profileLabel: {
        color: theme.palette.text.primary,
        fontWeight: '500',
        fontSize: '12px',
    },
    icon: {
      color: '#5A7679',
    },
    updated: {
        fontSize: '11px',
        '& .label': {
            marginRight: theme.spacing(1),
        },
        '& .date':{
            color: 'white',
        }
    },
    pr: {
        paddingRight: theme.spacing(1)
    },
    pl: {
        paddingLeft: theme.spacing(1)
    },
    owner: {
        fontSize: '12px',
        color: theme.palette.warning.main,
    }
});

const UserDetails = (props) => {
    const classes = useStyles();
    const {data, page, token, onchange, setRefresh, LoadData, companyList} = props;
    // const [companyInfo, setCompanyInfo] = useState({})
    const [openForm, setOpenForm] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [formtype, setFormtype] = useState(null);
    const handleOpen = (props) => {
      setFormtype(props);
      setOpenForm(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
      setOpenForm(false);
    };

    const changeFormStatus = (status) => {
        setIsChanged(status)
    }

    // useEffect(()=> {
    //     console.log(data)
    //     company.getOne(data.company_id,token).then((data)=>{
    //         setCompanyInfo(data.data.data)
    //         console.log(data.data.data)
    //     }).catch((e) => console.error(e));
    // },[props])

    return(
        <>
        { props.page === 'home' ?
            <div className={classes.homeRoot}>
            <Box className={classes.infoBox} width={1/4} display="flex" alignItems="center">
                <CustomAvatar img={data.company_image && data.company_image !== 'null' ? data.company_image : avatarImg} size="xsmall" />
                <span className="name">{data.first_name} {data.last_name} </span> 
            </Box>
            <Box className={classes.pl} width={1/4}>
                <span className="name">{data.role}</span> <span className={classes.owner}>{data.owner && '(O)'}</span>
            </Box>
            <Box className={classes.updated} flexGrow={1} textAlign="right" px={2}>
                <span className="label">Last Updated</span>
                <span className="date">{moment(data.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
            </Box>
            <Box width={1/12}>
                { data.role !== 'superadmin' && 
                    <>
                    <IconButton className={classes.icon} onClick={(e)=>handleOpen('edit')} size="small"><EditIcon fontSize="small" /></IconButton>
                    <IconButton color="secondary" onClick={(e)=>handleOpen('delete')} size="small"><DeleteIcon fontSize="small" /></IconButton>
                    </>
                }
            </Box>
        </div> :
            <Box className={classes.userRoot}>
                <Box className={classes.infoBox} >
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            ID
                        </div>
                        <div className={classes.data}>{data.userid}</div>
                    </div>
                </Box>
                <Box className={classes.infoBox} width={1/7} flexGrow={1}>
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            Name
                        </div>
                        <div className={classes.data}>{data.first_name} {data.last_name}</div>
                        {/* <div className={classes.data}>{data.username}</div> */}
                    </div>
                </Box>
                <Box className={classes.infoBox} width={1/12}>
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            Role
                        </div>
                        <div className={classes.data}>{data.role} <span className={classes.owner}>{data.owner && '(O)'}</span></div>
                    </div>
                </Box>
                <Box className={classes.infoBox} width={1/9}>
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            Title
                        </div>
                        <div className={classes.data}>{data.title}</div>
                    </div>
                </Box>
                <Box className={classes.infoBox} width={1/6} flexGrow={1}>
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            Email
                        </div>
                        <div className={classes.data}>{data.email}</div>
                    </div>
                </Box>
                <Box className={classes.infoBox} width={1/7} flexGrow={1}>
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            Mobile No.
                        </div>
                        <div className={classes.data}>{data.mobile_no}</div>
                    </div>
                </Box>
                <Box className={classes.infoBox} width={1/8}  flexGrow={1}>
                    <div className={classes.dataBox}>
                        <div className={classes.profileLabel}>
                            Company
                        </div>
                        <Box display="flex" alignItems="center">
                            <CustomAvatar img={data.company_image && data.company_image !== 'null' ? data.company_image : avatarImg} size="xsmall" />
                            <span className="name">{data.company_name || 'Super Admin'}</span>
                        </Box>
                    </div>
                </Box>
                <Box width={1/16}>
                    { data.role !== 'superadmin' && 
                    <>
                    <IconButton className={classes.icon} onClick={(e)=>handleOpen('edit')} ><EditIcon fontSize="small" /></IconButton>
                    <IconButton color="secondary" onClick={(e)=>handleOpen('delete')}><DeleteIcon fontSize="small" /></IconButton>
                    </>
                    }
                </Box>
            </Box>
            }
            <CustomModal
                open={openForm}
                close={handleClose}
                formStatus={isChanged}
                title={formtype === 'edit' ? 'Edit User': 'Delete User?'}
                titleStyle={formtype === 'edit' ? "primary" : "secondary" }
                ariaLabelledby={formtype === 'edit' ? "edit-company-form-title" : "delete-company-form-title"}
                ariaDescribedby={formtype === 'edit' ? "edit-company--form-description" : "delete-company--form-description"}>
                { page === 'home' ?
                    <>
                    {formtype === 'edit' ?
                        <EditUser token={token} data={data} companyList={companyList} id={data._id} onchange={changeFormStatus} page="home" />
                    : formtype === 'delete'?
                        <DeleteUser token={token} data={data} id={data._id} onchange={changeFormStatus} page="home" />
                    : null}
                    </>
                :
                <>
                {formtype === 'edit' ?
                    <EditUser LoadData={LoadData} token={token} data={data} companyList={companyList} id={data._id} onchange={changeFormStatus} setRefresh={setRefresh} />
                : formtype === 'delete'?
                    <DeleteUser token={token} data={data} id={data._id} onchange={changeFormStatus} LoadData={LoadData} setRefresh={setRefresh} />
                : null}
                </>
                }
            </CustomModal>
        
        </>
    )
};

export default UserDetails;