import React, {Component} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Box, CircularProgress} from '@material-ui/core';
import styled from 'styled-components';
import {theme} from '../theme/theme';
import users from '../pages/client/Auth'
import Company from './company';
import {MainContent} from './styled'
import UserDetails from './user';

class UserList extends Component {
    state={
        users: [],
        limit: 10,
        hasMore: true,
        count: 0,
        message: 'Loading...',
    }

    componentDidMount(){
        this.countData();
        this.loadData();
    }

    componentDidUpdate(){
        if (this.props.refresh) {
            console.log('refresh')
            this.countData();
            this.loadData();
        }
        // this.countData();
        // this.loadData();
    }

    loadData = () => {
        const {token, sorting, filter} = this.props;
        let sortString;
        console.log(sorting)
        switch (sorting) {
            case '1':
                sortString = '-az';
                break;
            case '2':
                sortString = '-notification_count';
                break;
            default:
                sortString = '-updatedAt';
                break;
        }
        if (filter !== 'all') {
            users.getList(token, {limit: this.state.limit,sort:'-updatedAt', company_id:filter}).then(({data}) => { 
                if (data || data.data ) {
                    this.setState(
                        {...this.state, users:data.data}
                    );
                } else {
                    console.log("invalid data");
                }
            }).catch((err) => {
                console.log(err.message)
            })
        } else {
            users.getList(token, {limit: this.state.limit,sort:'-updatedAt'}).then(({data}) => { 
                if (data || data.data ) {
                    this.setState(
                        {...this.state, users:data.data}
                    );
                } else {
                    console.log("invalid data");
                }
            }).catch((err) => {
                console.log(err.message)
            })
        }
    }

    countData = () => {
        const {token, filter} = this.props;
        let message = this.state.message;
        if (filter !== 'all') {
            users.getList(token,{company_id:filter}).then(({data}) => {
                if (data) {
                    if (data.data.length === 0) {
                        message = 'No data found'
                    }
                    this.setState(
                        {...this.state, count:data.data.length, message: message}
                    );
                }
            }).catch(console.error);
        } else {
            users.getList(token).then(({data}) => {
                if (data) {
                    if (data.data.length === 0) {
                        message = 'No data found'
                    }
                    this.setState(
                        {...this.state, count:data.data.length, message: message}
                    );
                }
            }).catch(console.error);
        }

    }

    fetchMoreData = () => {
        if (this.state.users.length >= this.state.count) {
            this.setState({ hasMore: false });
            return;
        }
        //console.log(this.state.trips.length, this.state.count)
        this.setState({...this.state, limit: this.state.limit+5})
        this.loadData();
    };

    increment = (el,index,array) => {
        el.userid = parseInt(index) + 1;
        return el
    }

    newUserArray = (arr,fn) => {
        const mapped = [];
        for (const index in arr) {
            mapped.push(fn(arr[index], index, arr));
        }
        return mapped;
    }

    render() {
       const {users,count,hasMore,message} = this.state;
       const {token, onchange, LoadData, setRefresh, companyList, filter} = this.props;
       const newUsers = this.newUserArray(users,this.increment)
       console.log(newUsers)
        return (
           <MainContent id="users">
               { users.length > 0 ?
                <InfiniteScroll
                    dataLength={users.length} //This is important field to render the next data
                    next={this.fetchMoreData}
                    hasMore={hasMore}
                    loader={<Box display="flex" alignItems="center" justifyContent="center"><CircularProgress color="secondary" /></Box>}
                    endMessage={
                        <Box textAlign="center">
                        <b>Yay! You have seen it all</b>
                        </Box>
                    }
                    scrollableTarget="users"
                    >
                    {/* {this.newUserArray(users,this.increment)} */}
                {newUsers.map((obj,key)=>
                    <UserDetails data={obj} key={key} companyList={companyList} token={token} onchange={onchange} LoadData={LoadData} setRefresh={setRefresh} />
                )}
                </InfiniteScroll>
                : <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    {message === 'Loading...' &&
                    <CircularProgress color="secondary" />
                    }
                    <br/>{message}</Box> }
           </MainContent>
        )
    }
};

export default UserList;