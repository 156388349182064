import React from 'react';
// imports for front end
import { theme } from '../../theme/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Profile from './profile';
import EmergencyContacts from './contacts';

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    profile: {
        width: '60%',
    }
});

const CompanyProfile = ({Company,Contact,onSubmit}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.profile}>
                <Profile Company={Company} Contact={Contact} onSubmit={onSubmit} /></div>
            <div><EmergencyContacts /></div>
        </div>
    )
};

export default CompanyProfile;