import axios from 'axios'
import {_request,getRequest,putRequest} from './Axios'
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
});



export default {
    login: async(payload) => {
        try {           
            console.log(payload)
            let response = await instance.post('/auth/superadminlogin',payload);
            return response.data;
        } catch(error) {
           if (error.response) {
               console.log(error.response.data)
               throw new Error(error.response.data.error.message);
           } else {
               console.log(error);
               throw new Error(error.message);
           }
        }
        
    },

    
    delete:(id,token) => {
        return _request(`/auth/${id}`,"delete",null,{
            Authorization: "Bearer " + token
        })
    },

    postInvite:(FormData,token) => {
        return _request(`/auth/invite`,"post",FormData,{
            "Authorization": "Bearer " + token,
         });
    },

    getList:(token,params) => {
        return getRequest(`/auth/`,{
            Authorization: "Bearer " + token
        },params);
    },

    putUpdate:(FormData,token,id) => {
        return _request(`/auth/${id}`,"put",FormData,{
            Authorization: "Bearer " + token
         });
    },

    postCreate:(FormData,token) => {
        return _request(`/auth/create`,"post",FormData,{
            "Authorization": "Bearer " + token,
            "Content-Type": 'application/json;charset=UTF-8',

         });
    },

    getUser:(token,id) => {
        return getRequest(`/auth/${id}`,{
            Authorization: "Bearer " + token
        });
    },

    register: async(payload) => {
        try {
            let response = await instance.post('/auth/signup',payload);
            return response.data;
        } catch(error) {
           if (error.response) {
        
               throw new Error(error.response.data.error.message);
           } else {
               throw new Error(error.message);
           }
        }

    },


    checkEmail: async(payload) => {
        try {
            let response = await instance.post('/auth/checkEmail',payload);
            return response.data;
        } catch(error) {
           if (error.response) {
                console.log(error)
               throw new Error(error.response.data.error.message);
           } else {
               throw new Error(error.message);
           }
        }

    },

    forgotPassword: async(payload) => {
        try {
            let response = await instance.post('/auth/forgot',payload);
            return response.data;
        } catch(error) {
            if (error.response) {
                throw new Error(error.response.data.error.message);
            } else {
                throw new Error(error.message);
            }
        }
    },

    resetPassword: async(payload) => {
        try {
            let response = await instance.post('/auth/reset',payload);
            return response.data;
        } catch(error) {
            if (error.response) {
                throw new Error(error.response.data.error.message);
            } else {
                throw new Error(error.message);
            }
        }
    },


    checkAuthenticated: async(token) => {
        let config = {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }
        try {
            let response = await instance.get('/auth/amiworthy',config);
            return response.data.status
        } catch(error) {
            console.error(error);
        }
    }
}