import React, {useState,useEffect} from 'react';
import { Grid, Box, Button, Input, FormControl, InputBase, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from '../../theme/theme'
import Select from '@material-ui/core/Select';
import { ToastContainer, toast } from 'react-toastify';
import {notify} from  '../../pages/client/Toast'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccidentClient from '../../pages/client/Accident'
import RiderClient from '../../pages/client/Rider'
import BikeClient from '../../pages/client/Bike'
import moment from 'moment'
import AlertMessage from './message';
import { IoMdTime } from 'react-icons/io';
import InputDatePicker from './inputDatePicker';
import InputTimePicker from './inputTimePicker';
import {useHistory } from "react-router-dom";
import { AccidentTypes } from './formOptions';
import { useForm, Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ImageIcon from '@material-ui/icons/Image';

const BootstrapInput = withStyles({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
})(InputBase);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    label: {
        display: 'block',
        color: '#4C5F69',
        margin: '8px 16px',
        fontWeight: 'normal',
    },
    icon: {
        color: theme.palette.secondary.light,
        marginRight: '8px',
    },
    iconOpen: {
        color: 'white',
    },
    //styling for select box
    select: {
        fontSize: '1.125rem',
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.dark,
        padding: '10px 0 10px 12px',
        position: 'relative',
        width: '360px',
        color: 'white',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '& .smaller': {
            width: '360px',
        },
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: theme.shape.borderRadius,
        },
    },
    //styling for select menu
    selectOptions: {
        width: '360px',
        backgroundColor: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.main,
        borderRadius: '0 0 8px 8px',
        border: 'none',
        borderTop: '2px solid',
        color: theme.palette.secondary.light,
        fontSize: '1.125rem',
        padding: '0px',
        marginLeft: '-13px',
        marginTop: '6px',
        boxShadow: 'none',
        "& .MuiList-padding": { paddingTop: '0px', paddingBottom: '0px'},
        "& .MuiListItem-root": {
            borderRadius: 0,
            padding: '10px 26px 10px 12px',
            width: '100%',
        },
        "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "transparent"
        },
        "select &:focus, select &:hover" : {
            border: 'collapse',
        }
    },
    input: {
        marginTop: theme.spacing(0),
        fontSize: '1.125rem',
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.dark,
        padding: '10px 0 10px 12px',
        position: 'relative',
        width: '360px',
        color: 'white',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: theme.shape.borderRadius,
        },
    },
    pickerInput: {
        marginTop: theme.spacing(0),
        border: '1px solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.dark,
        padding: '10px 0 10px 12px',
        position: 'relative',
        textDecoration: 'none',
        width: '346px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: theme.shape.borderRadius,
        },
    },
    link: {
        color: theme.palette.secondary.dark,
        fontWeight: 400,
    },
    button: {
        width: '360px',
        padding: '12px 0',
        fontSize: theme.typography.h4.fontSize,
        background: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        fontWeight: 600,
    },
    upload: {
        visibility: 'hidden',
        width: 0,
    },
    uploadLabel: {
        padding: '12px 0',
        borderRadius: theme.shape.borderRadius,
        border: 'none',
        borderColor: theme.palette.primary.main,
        color: theme.palette.secondary.dark,
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'block',
        maxWidth: '350px'
    },
    filename: {
        fontSize: '13px',
        color: theme.palette.text.header,
        wordBreak: 'break-all',
        maxWidth: '350px',
        display: 'block',
        verticalAlign: 'middle'
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
    pickerButton: {
      background: 'none',
      color: theme.palette.text.primary,
    },
    pickerRoot: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.header,
        '& .MuiPickersCalendarHeader-transitionContainer': {
        width: '120px',
        },
        '&::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)',
        },
    },
    inputText:{
        color: 'white',
        fontSize: '1.125rem',
    },
    fileIcon: {
        float: 'left',
        marginRight: theme.spacing(1),
        marginTop: '2px',
    }
});

// styling for select menu
const MenuProps = {
    PaperProps: {
        style: {
        width: '360px'
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

const ModalProps = {
    PaperProps: {
        style: {
            backgroundColor: '#28353D',
            color: '#fff',
        }
    },
}

const defaultValues = {
    model: 'undefined',
    rider: 'undefined',
    date: '',
    time: '',
    address: '',
    description: '',
    file:null,
    accident_type: 'undefined',
}

const defaultMessage = {
    title: null,
    content: null,
    type: 'red',
    open: false,
    // special case for report form
    // title: 'Report successfully sent!', 
    // content:'Emergency services will arrive on the scene shortly.',
    // type:'red'
}

const maxDate = new Date();
const minDate = new Date();
const ReportForm = (props) => {
    const token = props.token;
    const classes = useStyles();
    const { handleSubmit, control, formState: { errors }, register, setError, clearErrors,reset, watch, getValues, setValue } = useForm(
        {defaultValues}
    );
    MenuProps.PaperProps.className = classes.selectOptions;
    const url = new URL(window.location.href);
    const path = url.pathname.split("/");
    const history = useHistory();
    const [select, setSelect] = useState({
        bike: undefined,
        rider: undefined,
        accident_type: undefined
    })

    const [message, setMessage] = useState({
        ...defaultMessage
    });
    useEffect(() => {

        const tokenString = (typeof(token) == "string") ? token : token.token; 
        RiderClient.getList(tokenString).then(({data}) => {
            setRiders(data.data.riders);
        }).catch((e) =>  notify(e.message,"error"))

        BikeClient.getList(tokenString).then(({data}) => {
            setBikes(data.data.bikes);
        }).catch((e) =>  notify(e.message,"error"))
    },[])

    const [riders, setRiders] = useState([]);
    const [bikes, setBikes] = useState([]);
    const [date, setDate] = useState(maxDate);
    const [time, setTime] = useState(maxDate)
    const dateValue = getValues('date');
    const timeValue = getValues('time');

    const handleDateSelect = (date) => {
        props.onchange && props.onchange(true);
        let accdate= moment(date,"DD/MM/YYYY")
        console.log(date)
        setDate(date);
        setValue('date', moment(date).format("DD/MM/YYYY"));
        console.log(accdate.isValid())
        if (accdate.isValid() === false) {
            setError('date', {type:'invalid', message: 'Invalid Date'})
            console.log('Invalid Date Format')
        } else {
            clearErrors('date')
        }
    }
    const handleTimeSelect = (time) => {
        props.onchange && props.onchange(true);
        let acctime= moment(time,"HH:mm")
        console.log(moment(time).format("HH:mm"))
        setTime(time);
        setValue('time', moment(time).format("HH:mm"));
        console.log(acctime.isValid())
        if (acctime.isValid() === false) {
            setError('time', {type:'invalid', message: 'Invalid Time'})
            console.log('Invalid Time Format')
        } else {
            clearErrors('time')
        }
    }
    useEffect(() => {
        register('date',{required: true});
        register('time', {required: true})
    }, [register]);
    useEffect(() => {
        setDate(dateValue || null);
    }, [setDate, dateValue]);
    useEffect(() => {
        setTime(timeValue || null);
    }, [setDate, timeValue]);

    const handleFormStatus = (status) => {
        props.onchange && props.onchange(status);
    };

    const onBikeSelected = (e) => {
        console.log(e.target.value)
        let id = e.target.value
        BikeClient.getOne(id,token).then(({data}) => {
            let rider = data.data.rider_id;
            setSelect({...select,rider: rider})
            setValue('rider',rider)
        }).catch((e) =>  setValue('rider',null))
    }

    const onFileSelect = (e) => {
        e.preventDefault();
        props.onchange && props.onchange(true);
        const formData = new FormData();
        const selectedFile = e.target.files[0];
        console.log(selectedFile)
        setValue('file',selectedFile);
        // setValues({...values, file:selectedFile, filename: selectedFile.name})
    }

    const onFormSubmit = data => {
        // e.preventDefault();
        const tokenString = (typeof(token) == "string") ? token : token.token; 
        // console.log(tokenString);
        // console.log(data)
        let {model:bike_id, rider:rider_id, date,time,address,description,file,accident_type} = getValues()

        const jsonData = {
            bike_id,rider_id,address,description,file,datetime: moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm'), accident_type
        }

        const formData = new FormData();
        for ( var key in jsonData ) {
            formData.append(key, jsonData[key]);
        }   

        console.log(formData);
        AccidentClient.post(formData,token.token).then(({data}) => {
            setMessage({
                title: 'Report successfully sent!',
                content:'Emergency services will arrive on the scene shortly.',
                type:'red',
                open: true,
            });
            setTimeout(function(){
                handleClose();
                if (path[1] !== 'accidents') {
                    history.push(`/accidents/`);
                } else {
                    window.location.href = window.location.href;
                }
            }, 2000);
            props.close && setTimeout(props.close, 2000);
            props.onchange && props.onchange(false);
            reset();
        }).catch((e) =>  {
            //notify(e.message,"error")
            setMessage({
                title: 'Failed',
                content:`${e.message} Please try again.`,
                type:'red',
                open: true,
            });
        });

    }

    const handleClose = () => {
      setMessage({
          ...defaultMessage
      });
    };

    console.log(select, getValues(),watch())
    return (
        <>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Box className={classes.root}>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <label htmlFor="model-bike" className={classes.label}>Model Bike</label>
                            <Controller
                                control={control}
                                name="model"
                                rules={{required: true}}
                                value={getValues('model')}
                                render={({ field }) =>(
                                    <Select
                                        classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
                                        className={classes.select}
                                        id="model-bike"
                                        input={<BootstrapInput />}
                                        MenuProps={MenuProps}
                                        IconComponent={ExpandMoreIcon}
                                        value={getValues('model')}
                                        {...field}
                                        onChange={(e)=>{
                                            field.onChange(e);
                                            onBikeSelected(e);
                                            handleFormStatus(true);
                                        }}
                                    >
                                        <MenuItem value="undefined" key="undefined1">
                                            <em>None</em>
                                        </MenuItem>
                                        {bikes.map((obj, key) => 
                                            <MenuItem value={obj._id} key={key}>{obj.type} {obj.model}</MenuItem>
                                        )}
                                    </Select>
                                )}
                            />
                            <div className={classes.error}>
                                {errors.model?.type === 'required' && "*Required"}
                            </div>
                        </Grid>
                        {/*  right column */}
                        <Grid item xs={6}>
                            <label htmlFor="rider" className={classes.label}>Rider</label>
                            <Controller
                                control={control}
                                name="rider"
                                rules={{required: true}}
                                value={getValues('rider')}
                                render={({ field }) =>(
                                    <Select
                                        classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
                                        className={classes.select}
                                        id="rider"
                                        input={<BootstrapInput />}
                                        MenuProps={MenuProps}
                                        IconComponent={ExpandMoreIcon}
                                        {...field}
                                        onChange={(e)=>{
                                            field.onChange(e);
                                            handleFormStatus(true)
                                        }}
                                    >
                                            <MenuItem value="undefined" key="undefined2">
                                                <em>None</em>
                                            </MenuItem>
                                        {riders.map((obj, key) => 
                                            <MenuItem value={obj._id} key={key}>{obj.name}</MenuItem>
                                        )}
                                    </Select>
                                )}
                            />
                            <div className={classes.error}>
                                {errors.rider?.type === 'required' && "*Required"}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        {/* left column */}
                        <Grid item xs={6}>
                            <label htmlFor="date" className={classes.label}>Date</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className={classes.pickerRoot}>
                                <KeyboardDatePicker
                                    clearable
                                    maxDate={maxDate}
                                    openTo="date"
                                    disableToolbar
                                    variant="dialog"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date"
                                    placeholder={moment(maxDate).format('DD/MM/YYYY')}
                                    helperText={''}
                                    value={date}
                                    onChange={date => handleDateSelect(date)}
                                    InputProps={{
                                        disableUnderline:true,
                                        classes: {
                                            input: classes.inputText,
                                        },
                                        }}
                                    DialogProps={ModalProps}
                                    leftArrowButtonProps={{classes: {root: classes.pickerButton}}}
                                    rightArrowButtonProps={{classes: {root: classes.pickerButton}}}
                                    className={classes.pickerInput}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <div className={classes.error}>
                            {errors.date?.type === 'required' && "*Required"}
                        </div>
                        </Grid>
                        {/*  right column */}
                        <Grid item xs={6}>
                            <label htmlFor="time" className={classes.label}>Time</label>
                            {/* <InputTimePicker onDatechange={handleChange('time')} /> */}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className={classes.pickerRoot}>
                                <KeyboardTimePicker
                                    ampm={false}
                                    variant="dialog"
                                    openTo="hours"
                                    keyboardIcon={<IoMdTime/>}
                                    margin="normal"
                                    id="time-picker-inline"
                                    placeholder={moment(maxDate).format('HH:mm')}
                                    disableToolbar
                                    helperText={''}
                                    value={time}
                                    maxDate={new Date()}
                                    onChange={time => handleTimeSelect(time)}
                                    InputProps={{
                                    disableUnderline:true,
                                    classes: {
                                        input: classes.inputText,
                                    }
                                    }}
                                    DialogProps={ModalProps}
                                    leftArrowButtonProps={{classes: {root: classes.button}}}
                                    rightArrowButtonProps={{classes: {root: classes.button}}}
                                    className={classes.pickerInput}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <div className={classes.error}>
                            {errors.time?.type === 'required' && "*Required"}
                        </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        {/* left column */}
                        <Grid item xs={6}>
                            <label htmlFor="address" className={classes.label}>Address</label>
                            <Controller 
                                control={control}
                                name="address"
                                rules={{required: true}}
                                render={({ field }) => 
                                <InputBase
                                    className={classes.input}
                                    id="address"
                                    type="text"
                                    placeholder="123 Abc Street"
                                    {...field}
                                    onChange={(e)=>{field.onChange(e); handleFormStatus(true);}}
                                    />}
                                />
                            <div className={classes.error}>
                                {errors.address?.type === 'required' && "*Required"}
                            </div>
                        </Grid>
                        {/*  right column */}
                        <Grid item xs={6}>
                            <label htmlFor="accident_type" className={classes.label}>Accident Type</label>
                            <Controller
                                control={control}
                                name="accident_type"
                                rules={{required: false}}
                                value={getValues('accident_type')}
                                render={({ field }) =>(
                                    <Select
                                        classes={{ icon: classes.icon, iconOpen: classes.iconOpen}}
                                        className={classes.select}
                                        id="accident_type"
                                        input={<BootstrapInput />}
                                        MenuProps={MenuProps}
                                        IconComponent={ExpandMoreIcon}
                                        {...field}
                                        onChange={(e)=>{
                                            field.onChange(e);
                                            handleFormStatus(true)
                                        }}
                                    >   
                                        <MenuItem value="undefined" key="undefined3">
                                            <em>None</em>
                                        </MenuItem>
                                        {AccidentTypes.map((type,key)=>
                                            <MenuItem value={type.value}>
                                                {type.label}
                                            </MenuItem>
                                        )}
                                    </Select>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className={classes.marginTop}>
                        <Grid item xs={6}>
                            <label htmlFor="description" className={classes.label}>Description</label>
                            <Controller 
                                control={control}
                                name="description"
                                rules={{required: true}}
                                render={({ field }) => 
                                <InputBase
                                    className={classes.input}
                                    multiline
                                    rows={3}
                                    id="description"
                                    type="text"
                                    placeholder="Description of accident"
                                    {...field}
                                    onChange={(e)=>{field.onChange(e); handleFormStatus(true);}}
                                    />}
                                    
                                />
                            <div className={classes.error}>
                                {errors.description?.type === 'required' && "*Required"}
                            </div>
                        </Grid>
                        <Grid item sx={6}>
                            <div style={{marginTop: '1em'}}>
                                <label htmlFor="file" className={classes.uploadLabel}>
                                    Upload image/document
                                    <Controller
                                        name="file"
                                        control={control}
                                        render={({ field }) => (
                                        <Input
                                            id="file"
                                            type="file"
                                            className={classes.upload}
                                            {...field}
                                            onChange={(e) => {field.onChange(e);onFileSelect(e)}} 
                                            value={(e) => e.target.value}
                                        />)}
                                    />
                                </label>
                                <Box className={classes.filename} display="flex">
                                    {getValues('file') && getValues('file').type.match('image/*') ? <ImageIcon fontSize="medium" className={classes.fileIcon} /> : getValues('file') && getValues('file').type.match('image/*') ? <InsertDriveFileIcon fontSize="medium" className={classes.fileIcon} /> : null  }
                                    {getValues('file') && getValues('file').name}
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className={classes.marginTop}>
                        <Grid item xs={6}>
                            <Button type="submit" className={classes.button} variant="contained">REPORT ACCIDENT</Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
            <AlertMessage
                open={message.open}
                close={handleClose}
                title={message.title}
                titleStyle={message.type}
                ariaLabelledby="report-accident-message"
                ariaDescribedby="report-accident-message">
                {message.content}
            </AlertMessage>
        </>
    )

};

export default ReportForm;
