import React from 'react';
import PropTypes from 'prop-types';
import iconPath from "../assets/iconsLib"

const defaultStyles = {
    display: "inline-block",
    verticalAlign: "middle",
};

const Icon = ({ width, height, color, icon, style, className, viewBox, stroke }) => {
    const styles = {...defaultStyles, ...style };
    return (
            <svg
            className={className}
            style={defaultStyles}
            viewBox={viewBox}
            width={`${width}px`}
            height={`${height}px`}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
         >
             {style ?
             <path fill={color} d={iconPath[icon]} style={style} />
             : <path fill={color} d={iconPath[icon]} />
             }
         </svg>
    )
}

Icon.defaultProps = {
    size: 16,
    width: 24,
    height: 24,
    color: "#000000",
    viewBox: "0 0 24 24",
    style: {},
    className: "",
};

Icon.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    viewBox: PropTypes.string.isRequired,
    // style: PropTypes.shape(PropTypes.object),
    className: PropTypes.string,
};

export default Icon;