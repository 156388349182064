import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
        Dialog, DialogActions,
        DialogContent, DialogContentText,
        DialogTitle
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { theme } from "../../theme/theme"

const useStyles = makeStyles({
    paper: {
        backgroundColor: theme.palette.background.primary,
        padding: theme.spacing(2, 4, 3),
    },
    title: {
        color: theme.palette.secondary.main,
    },
    desc: {
        color: theme.palette.text.primary,
    }
})

export default function AlertDialog(props) {
    const classes = useStyles();
    return(
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{paper: classes.paper}}
        >
                <DialogTitle id="alert-dialog-title" className={classes.title}>
                    {"Data is not saved!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.desc}>
                        The changes made in the form will not be saved if you close the modal.<br/>
                        Are you sure you want close it now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e)=>props.handleClose('close')} variant="contained" color="secondary">
                        Close
                    </Button>
                    <Button onClick={(e)=>props.handleClose('save')} variant="outlined" color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
    )
}