import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { theme } from '../theme/theme'
import { Box } from '@material-ui/core';

const StatusBox = withStyles((customTheme)=>({
    root: {
        borderRadius: '10px',
        borderColor: theme.palette.text.header,
        backgroundColor: theme.palette.background.dark,
        padding: theme.spacing(1,2),
        width: '100%',
        color: theme.palette.text.muted,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '1rem',
        '& span': {
            fontWeight: '600',
            marginLeft: '8px',
        },
        '& .total': {
            color: theme.palette.text.header,
        },
        '& .online': {
            color: theme.palette.primary.main,
        },
        '& .offline': {
            color: theme.palette.secondary.main,
        },
        '& .repair': {
            color: theme.palette.warning.main,
        },
    }
}))(Box);

const Status = (props) => {
    const {statusValues, data} = props;
    return (
        <StatusBox>
            {data === 'company' ? 
            <>
            <Box width={1/3}>Total<span className="total">{statusValues.total}</span></Box>
            <Box width={1/3}>Active<span className="online">{statusValues.active}</span></Box>
            <Box width={1/3}>Disabled<span className="offline">{statusValues.disabled}</span></Box>
            </>
            :
            <>
            <Box width={1/3}>Total<span className="total">{statusValues.total}</span></Box>
            <Box width={1/3}>Admin<span className="online">{statusValues.admin}</span></Box>
            <Box width={1/3}>Manager<span className="online">{statusValues.manager}</span></Box>
            </> }
        </StatusBox>
    )
}

export default Status;