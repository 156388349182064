import React, {useState, useEffect,useRef} from 'react';
import {useHistory } from "react-router-dom";
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { Button, InputBase, Input, Box, Grid,
         Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import companyClient from '../../pages/client/company'
import CustomAvatar from '../avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import AlertMessage from './message';
import placeholder from '../../assets/images/placeholder.jpg'
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const GreyCheckbox = withStyles({
  root: {
    color: grey[400],
    border: 'none',
    '&$checked': {
      color: 'none',
      border: 'none',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
    root: {
        maxHeight: '570px',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: '32px',
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.input,
        }
    },
    sectionLabel:{
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
    },
    input: {
        marginTop: theme.spacing(1),
        fontWeight: '500',
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.input,
        padding: '8px 12px',
        position: 'relative',
        width: '420px',
        color: theme.palette.text.header,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus, &:hover, &:active, &:focus-visible, &:focus-within': {
            border: 'collapse',
            borderColor: 'transparent',
            opacity: '0.8',
            borderRadius: theme.shape.borderRadius,
        },
    },
    upload: {
        visibility: 'hidden',
        width: 0,
    },
    uploadLabel: {
        padding: '8px 24px',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginLeft: theme.spacing(4),
        fontWeight: '500',
        fontSize: '14px',
    },
    label: {
        display: 'block',
        margin: '6px 16px',
        fontWeight: 'normal',
    },
    button: {
        padding: '12px 40px',
    },
    pr16: {
        paddingRight: '16px',
    },
    padLeft: {
        paddingLeft: '16px',
    },
    fields: {
        margin: theme.spacing(2),
    },
    heading: {
        ...theme.typography.h4,
        color: theme.palette.text.header,
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    error: {
        color: theme.palette.error.main,
        fontSize: '12px',
    },
    divider: {
        backgroundColor: theme.palette.background.input,
        margin: '8px auto',
    },
    icon: {
        borderRadius: 3,
        width: 24,
        height: 24,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#28353D',
        '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E" +
            "%3Crect width='24' height='24' rx='2' fill='#28353D'/%3E" +
            "%3C/svg%3E\")",
        content: '""',
        },
        '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
        },
        'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#28353D',
        // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrYm94Ij4KPHJlY3QgaWQ9IlJlY3RhbmdsZSAyODc5MTcyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHJ4PSIyIiBmaWxsPSIjMjgzNTNEIi8+CjxnIGlkPSJ0aWNrIDEiIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPgo8ZyBpZD0iR3JvdXAiPgo8ZyBpZD0iR3JvdXBfMiI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xOS43NjU3IDYuMzU5MzNDMTkuNDUzMyA2LjA0Njg5IDE4Ljk0NjggNi4wNDY4OSAxOC42MzQzIDYuMzU5MzNMOS4wNDk4MyAxNS45NDM5TDUuMzY1NyAxMi4yNTk4QzUuMDUzMyAxMS45NDc0IDQuNTQ2OCAxMS45NDc0IDQuMjM0MzMgMTIuMjU5OEMzLjkyMTg5IDEyLjU3MjIgMy45MjE4OSAxMy4wNzg3IDQuMjM0MzMgMTMuMzkxMUw4LjQ4NDE0IDE3LjY0MDlDOC43OTY0NSAxNy45NTMzIDkuMzAzMzMgMTcuOTUzMSA5LjYxNTUyIDE3LjY0MDlMMTkuNzY1NyA3LjQ5MDdDMjAuMDc4MSA3LjE3ODMgMjAuMDc4MSA2LjY3MTc3IDE5Ljc2NTcgNi4zNTkzM1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9nPgo8L2c+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0IDQpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')",
        content: '""',
        },
    },
})

const phoneInputStyles = {
    backgroundColor: theme.palette.background.input,
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px `,
    border: `1px solid ${theme.palette.background.input}`,
    color: 'white',
    borderRadius: '10px',
    fontSize: '1rem',
    width: '420px',

}
const phoneButtonStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    padding: `0 ${theme.spacing(1)}px`,
    borderRadius: '10px 0 0 10px',
    fontSize: '1rem',
}

const phoneDropdownStyles = {
    backgroundColor: theme.palette.background.input,
    border: `1px solid ${theme.palette.background.input}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
    paddingRight: theme.spacing(2),
    fontSize: '1rem',
}

const defaultMessage = {
    // title: 'Failed', content:`Please try again`, type:'red', open: true
    // title: 'Successful!', content:'New rider has been added succesfully!', type:'green',open: true
    title: null,
    content: null,
    type: null,
    open: false,
}

const EditCompany = (props) => {
    console.log(props)
    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

        return [ htmlElRef, setFocus ] 
    }
    const company = props.data;
    const classes = useStyles();
    const [preview, setPreview] = useState(company.image || placeholder);
    const [disabled, setDisabled] = useState(company.status === 'disabled')
    const [inputRef, setInputFocus] = useFocus();
    const [status,setStatus] = useState(0);
    
    console.log(company)
    const defaultValues = {
      companyName: company.name,
      image: company.image,
      firstName: company.contact_person.first_name,
      lastName: company.contact_person.last_name,
      mobile: company.contact_person.mobile_no,
      email: company.contact_person.email,
      max_video_snippet_per_month: company.max_video_snippet_per_month,
      max_video_minute_per_month: company.max_video_minute_per_month,
      status: "active",
      lat: company.center.coordinates[1],
      long: company.center.coordinates[0],
    };
    const [message, setMessage] = useState({
        ...defaultMessage
    });
    const history = useHistory();
    const { handleSubmit, control, formState: { errors }, reset, watch, getValues, setValue } = useForm({
        defaultValues
    });

    function onFileSelect(e) {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        // console.log(selectedFile.name)
        setPreview(URL.createObjectURL(selectedFile))
        setValue('image',selectedFile);
    }

    const onFormSubmit = data => {
        // e.preventDefault();
        // onSubmit(data);
        console.log('submit',data)
        const statustoPut = disabled ? "disabled" : "active";

        let newData = {
          ...data,
          center: { coordinates: [data.long, data.lat] },
          status: statustoPut,
        };

        const formData = new FormData();
        for (var key in newData) {
          formData.append(key, newData[key]);
          console.log(key, newData[key]);
        }
        // formData.set('status', statustoPut)
        // formData.set('center', {coordinates: [data.long, data.lat]})

        companyClient
          .putUpdate(newData, props.token, company._id)
          .then((data) => {
            setMessage({
              title: "Successful!",
              content: "Company information has been updated succesfully!",
              type: "green",
              open: true,
            });
            props.onchange && props.onchange(false);

            setStatus(1);
          })
          .catch((e) => {
            setMessage({
              title: "Failed",
              content: `${e.message} Please try again`,
              type: "red",
              open: true,
            });
            setStatus(2);
          });
    }
    useEffect(()=> {
        setInputFocus()
        //console.log('rendered',reset)
    },[])

    const handleClose = () => {
      setMessage({
          ...defaultMessage
      });
      if (props.page === 'home') {
            history.push("/companies")
        } else {
            props.setRefresh && props.setRefresh(true);
        }
      setStatus(0)
    };

    const handleDisable = () => {
        setDisabled(!disabled)
    }

    return (
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container direction="row">
            <Grid item sx>
              <div className={classes.fields}>
                <label htmlFor="companyName" className={classes.label}>
                  Company Name *
                </label>
                <Controller
                  control={control}
                  name="companyName"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputBase
                      autoFocus
                      inputRef={inputRef}
                      className={classes.input}
                      value={getValues("companyName")}
                      id="companyName"
                      type="text"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="Company Name"
                    />
                  )}
                />
                <div className={classes.error}>
                  {errors.companyName?.type === "required" && "*Required"}
                </div>
              </div>
              <div className={classes.fields}>
                <FormControlLabel
                  control={
                    <GreyCheckbox
                      checked={disabled}
                      onChange={(e) => {
                        handleDisable();
                        props.onchange(true);
                      }}
                      name="repair"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label="Disabled"
                  labelStyle={classes.checkLabel}
                />
              </div>
            </Grid>
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label className={classes.label}>Company Logo</label>
                <Box display="flex" alignItems="center">
                  <CustomAvatar img={preview} size="large" />
                  <Box className={classes.padLeft}>
                    <label htmlFor="image" className={classes.uploadLabel}>
                      Upload Image
                      <Controller
                        name="image"
                        control={control}
                        // rules={{required: true}}
                        render={({ field }) => (
                          <Input
                            id="image"
                            type="file"
                            className={classes.upload}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              onFileSelect(e);
                            }}
                            value={(e) => e.target.value}
                          />
                        )}
                      />
                    </label>
                  </Box>
                </Box>
                {/* <div className={classes.error}>
                            {errors.companyName?.type === 'required' && "*Required"}
                        </div> */}
              </div>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.sectionLabel}>Contact Person</div>
          <Grid container direction="row">
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="first-name" className={classes.label}>
                  First Name
                </label>
                <Controller
                  control={control}
                  name="firstName"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="firstName"
                      type="text"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="First Name"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="last-name" className={classes.label}>
                  Last Name
                </label>
                <Controller
                  control={control}
                  name="lastName"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="lastName"
                      type="text"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="Last Name"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="mobile" className={classes.label}>
                  Mobile Number
                </label>
                <Controller
                  name="mobile"
                  control={control}
                  value={getValues("mobile")}
                  render={({ field }) => {
                    return (
                      <PhoneInput
                        {...field}
                        placeholder="+65 9123 4567"
                        inputProps={{
                          name: "mobile",
                        }}
                        country={"sg"}
                        onChange={(e) => {
                          field.onChange(e);
                          props.onchange(true);
                        }}
                        containerStyle={{ marginTop: "12px" }}
                        inputStyle={phoneInputStyles}
                        buttonStyle={phoneButtonStyles}
                        dropdownStyle={phoneDropdownStyles}
                        value={field.value}
                      />
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="email" className={classes.label}>
                  Email
                </label>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: false,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="email"
                      type="text"
                      placeholder="john@doe.com"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                    />
                  )}
                />
                <div className={classes.error}>
                  {errors.email?.type === "pattern" && "Invalid Email Address"}
                </div>
              </div>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container direction="row">
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="first-name" className={classes.label}>
                  Max Video Snippets per month
                </label>
                <Controller
                  control={control}
                  name="max_video_snippet_per_month"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="max_video_snippet_per_month"
                      type="number"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="Number e.g. 60"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="last-name" className={classes.label}>
                  Max Blackbox Video minutes per month
                </label>
                <Controller
                  control={control}
                  name="max_video_minute_per_month"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="max_video_minute_per_month"
                      type="number"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="Number e.g. 15"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="first-name" className={classes.label}>
                  Latitude
                </label>
                <Controller
                  control={control}
                  name="lat"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="lat"
                      type="number"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="Number e.g. 112"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item sx={6}>
              <div className={classes.fields}>
                <label htmlFor="last-name" className={classes.label}>
                  Longitude
                </label>
                <Controller
                  control={control}
                  name="long"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputBase
                      className={classes.input}
                      id="long"
                      type="number"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        props.onchange(true);
                      }}
                      placeholder="Number e.g. 15"
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.fields}>
            <Button
              type="submit"
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </form>
        <AlertMessage
          open={message.open}
          close={handleClose}
          title={message.title}
          titleStyle={message.type}
          ariaLabelledby="edit-company-message"
          ariaDescribedby="edit-company-message"
        >
          <Box textAlign="center">
            {message.content}
            <Box mt={4}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                OK
              </Button>
            </Box>
          </Box>
        </AlertMessage>
      </div>
    );
};

export default EditCompany;