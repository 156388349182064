import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BikeClient from '../pages/client/Bike';
import IconButton from '@material-ui/core/IconButton';
import Icon from '../components/icon';
import {useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 482,
        marginRight: theme.spacing(3)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        color: 'grey'
    },
    iconButton: {
        padding: 10,
        color: 'primary'
    }
}));

export default function SearchInput({token}) {
    const classes = useStyles();
    const history = useHistory();
    const [countries, setCountries] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    useEffect(() => {
       
        BikeClient.getPrefetchedResult(token).then(({data}) => {
            let tempData = data.data; 
            tempData = tempData.filter((x) =>  (x.title));
            setCountries(tempData);
        }).catch(console.error);
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const selectedData = countries.filter(({title}) => title == selectedValue)
   
        if (selectedData.length > 0) {
            if (selectedData[0].type == "bike") {
                console.log("redirect to bike page");
                history.push(`/bike/${selectedData[0].id}`);
            } else {
                console.log("redirect to rider page");
                history.push(`/rider/${selectedData[0].id}`);
            }    
        }
     

    }

    return(
        <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
            <Autocomplete freeSolo id="combo-box-demo" options={countries} getOptionLabel={(option) => option ? option.title :  ""}
            style={{ width: 600 }}
                renderInput={(params) => {
                    setSelectedValue(params.inputProps.value)
                    return (<TextField {...params}/>)
                } } 
                
                />
            {/* <InputBase className={classes.input} placeholder="Search" inputProps={{ 'aria-label': 'Search' }} /> */}
            <IconButton type="submit"  className={classes.iconButton} aria-label="search">
                <Icon icon="search" width={24} height={24} color="primary" />
            </IconButton>
        </Paper>
    )
}