import React, { useState } from 'react';
import { theme } from '../../theme/theme';
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import AlertMessage from '../forms/message';
import {useHistory } from "react-router-dom";
import CustomAvatar from '../avatar';
import AuthClient from '../../pages/client/Auth'

const useStyles = makeStyles({
    root: {
        width: '450px',
    },
    name: {
        ...theme.typography.h4,
        color: theme.palette.text.header,
        display: 'block',
        marginBottom: '0.5rem',
    },
    id: {
        fontSize: '12px',
    },
    marginRight: {
        marginRight: theme.spacing(2),
    },
    button: {
        padding: `${theme.spacing(1)}px 40px`,
        marginRight: theme.spacing(1),
        '&.delete': {
            fontWeight: '600',
        }
    }
})
const defaultMessage = {
    title: null,
    content: null,
    type: null, //green for success, red for error
    open: false,
}

const DeleteUser = (props) => {
    const data = props.data;
    const classes = useStyles();
    const history = useHistory();
    const [message, setMessage] = useState({
        ...defaultMessage
    });

    const handleClose = () => {
        // console.log("click handleclose")
        setMessage({
            ...defaultMessage
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        AuthClient.delete(data._id,props.token).then(({data}) => {

            setMessage({title: 'Successful!', content:'User has been deleted!', type:'green',open:true});
            setTimeout(function(){
                window.location.reload();
            }, 1000);
        }).catch((e) => setMessage({title: 'Failed', content:`${e.message} Please try again`, type:'red',open:true}));

    }

    return(

        <div className={classes.root}>
            <Box display="flex" p={3}>
                <div>
                    <div className={classes.name}>{data.first_name} {data.last_name}</div>
                    <div className={classes.name}>{data.email}</div>
                </div>
            </Box>
            <form onSubmit={(e) => handleSubmit(e)}>
                <Box display="flex" p={3}>
                    <Button type="submit" className={`${classes.button} delete`} variant="contained" color="secondary">Confirm Delete</Button>
                </Box>
            </form>
            <AlertMessage
                open={message.open}
                close={handleClose}
                title={message.title}
                titleStyle={message.type}
                ariaLabelledby="delete-user-message"
                ariaDescribedby="delete-user-message-content">
                {message.content}
            </AlertMessage>
        </div>
    )
};

export default DeleteUser;