import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Popper, IconButton, MenuItem, Paper, Box } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';

const useStyles = makeStyles((theme) => ({
      popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${theme.palette.background.input} transparent`,
        },
        },
        '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${theme.palette.background.input} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${theme.palette.background.input} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${theme.palette.background.input}`,
        },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
        content: '""',
        margin: 'auto',
        marginTop: '1em',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        },
    },
    paper: {
        background: theme.palette.background.input,
        padding: '8px 16px',
        marginTop: '0.5em',
    },
    square: {
      background: "#283637",
      color: '#587578',
      borderRadius: '8px',
    },
    menu: {
       '&:hover': {
           background: theme.palette.background.input,
       },
    },
    delete: {
        color: theme.palette.alert.main,
        '&:hover': {
        background: theme.palette.background.input,
    },
    },
    icon: {
        color: theme.palette.primary.main,
    }
}));

export default function CustomPopover({menuList,urlkey}) {
    const classes = useStyles();
    const [arrowRef, setArrowRef] = useState('arrow');
    const arrow=true;
    const [anchorEl, setAnchorEl] = useState(null);
    // const anchorRef = React.useRef(null);
    const [open, setOpen] = useState(false);
    const handleClickButton = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };

    // const handleClick = (e) => {
    //     
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <div>
            <IconButton aria-describedby={id} className={classes.square} onClick={handleClickButton}>
                <MoreHorizIcon />
            </IconButton>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClickButton}
                placement="bottom"
                className={classes.popper}
                modifiers={{
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                }}
            >
                {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
                <Paper className={classes.paper}>
                    <Box display="flex">
                        <Box style={{marginTop: '8px'}}>
                            {menuList.map((item, key) =>
                                <MenuItem className={classes.menu} key={key} value={item.value}>{item.label}</MenuItem>
                            )}
                            {/* <MenuItem className={classes.menu}>Edit</MenuItem>
                            <MenuItem className={classes.delete}>Delete</MenuItem> */}
                        </Box>
                        <Box><IconButton className={classes.square} onClick={handleClickButton}><ExpandLessRoundedIcon className={classes.icon} /></IconButton></Box>
                    </Box>
                </Paper>
            </Popper>
        </div>
    )
}