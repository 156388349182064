import React, { useState,useEffect, Suspense,lazy }from 'react';
import Layout from '../layout/common';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../theme/theme';
import { Box, CircularProgress, IconButton, Button } from '@material-ui/core'
import Users from '../pages/client/Auth'
import CompanyClient from './client/company'
import moment from 'moment-timezone';
import avatarImg from '../assets/images/general-post-office.png'
import Icon from '../components/icon';
import FilterList from '../components/forms/filterList';
import Company from '../components/company';
import StatusBox from '../components/statusBox';
import User from '../components/user';
import CustomModal from '../components/modal';
import AddCompany from '../components/forms/addCompany';
import AddUser from '../components/forms/addUser'


const useStyles = makeStyles({
  root: {
    overflowY: 'hidden',
    paddingRight: theme.spacing(1),
    display: 'flex',
    '& > div': {
        // flex: 1,
        borderRadius: '15px',
        border: '1px solid #273536',
        boxSizing: 'border-box',
        padding: theme.spacing(1,2),
        margin: theme.spacing(0,1),
    }
  },
  greeting: {
    background: theme.palette.background.primary,
    borderRadius: '15px',
    margin: theme.spacing(0,2,2,1),
    padding: theme.spacing(3,2)
  },
  heading: {
      color: theme.palette.text.header,
      fontWeight: '500',
      margin: theme.spacing(0.5,0),
  },
  companyBox: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
  },
  button: {
      padding: theme.spacing(1.5,3),
  },
  remark: {
      marginBottom: theme.spacing(1),
  },
  seemore: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '100px',
        padding: '8px 24px',
        '&:hover': {
            border: `2px solid ${theme.palette.primary.dark}`,
        }
    },
});
const sortList = [
        {
            label: 'Daily',
            value: 'day',
        },
        {
            label: 'Weekly',
            value: 'week',
        },
        {
            label: 'Monthly',
            value: 'month',
        }
    ];

const Home = ({token,cookie}) => {
  const classes = useStyles();
  const [company, setCompany] = useState();
  const [allCompany, setAllCompany] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [formtype, setFormtype] = useState(null);
  const [companyCount, setCompanyCount] = useState({
      total: 0,
      active: 0,
      disabled: 0,
  });

  const [userCount, setUserCount] = useState({
      total: 0,
      admin: 0,
      manager: 0,
  });
  const [users, setUsers] = useState();
  const handleOpen = (props) => {
      setFormtype(props);
      setOpenForm(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
      setOpenForm(false);
    };

    const changeFormStatus = (status) => {
        setIsChanged(status)
    }
    useEffect(() => {
       Users.getList(token,{limit: 4, sort:'-updatedAt'}).then(({data}) => {
        setUsers(data.data)
       }).catch(console.error)

       Users.getList(token).then(({data}) => {
        countUser(data.data)
       }).catch(console.error)

        CompanyClient.getList(token, {limit: 4,sort:'-updatedAt'}).then(({data}) => {
          setCompany(data.data);
          // console.log(company);
        }).catch(console.error);

        CompanyClient.getList(token).then(({data}) => {
        //    console.log(data.data.length)
          setAllCompany(data.data)
          countCompany(data.data)
          // console.log(company);
        }).catch(console.error);
    },[])

    const countCompany = (data) => {
        const active = data.filter((x)=> x.status === 'active')
        const disabled = data.filter((y)=> y.status === 'disabled')
        setCompanyCount({total: data.length, active: active.length, disabled: disabled.length})
    }

    const countUser = (data) => {
        console.log(data)
        const admin = data.filter((x)=> x.role === 'admin')
        const manager = data.filter((y)=> y.role === 'manager')
        setUserCount({total: data.length, admin: admin.length, manager: manager.length})
    }

    return (
      <Layout token={token}>
          <Box p={3} textAlign="center" className={classes.greeting}>
            <h2 className={classes.heading}>Welcome Back! <br/>What would you like to do?</h2>
            <Button className={classes.button} variant="contained" color="primary" onClick={(e)=>handleOpen('company')}>Add Company</Button>&nbsp;&nbsp;
            <Button className={classes.button} variant="contained" color="primary" onClick={(e)=>handleOpen('user')}>Add User</Button>
          </Box>
        <div className={classes.root}>
            <Box width={4/9}>
                <Box display="flex" p={1} my={2}>
                    <h2 className={classes.heading}>Companies</h2>
                    <Box px={3} flexGrow={1}>
                        <StatusBox statusValues={companyCount} data="company" />
                    </Box>
                </Box>
                <div className={classes.companyBox}>
                    { company && company.length > 0 && company.map((obj, key)=>
                        <Company data={obj} key={key} page="home" token={token} />
                    )}
                </div>
                <Box textAlign="center">
                    <Button component={RouterLink} to="/companies/" className={classes.seemore} color="primary" variant="outlined">See More</Button>
                </Box>
            </Box>
            <Box width={5/9}>
                <Box display="flex" p={1} my={2} >
                    <h2 className={classes.heading}>Users</h2>
                    <Box px={3} width={1/2}>
                        <StatusBox statusValues={userCount} data="user" />
                    </Box>
                </Box>
                <div className={classes.companyBox}>
                    { users && users.length > 0 && users.map((obj, key)=>
                        <User data={obj} key={key} token={token} page="home" companyList={allCompany} />
                    )}
                </div>
                <Box textAlign="center">
                    <Button component={RouterLink} to="/users/" className={classes.seemore} color="primary" variant="outlined">See More</Button>
                </Box>
            </Box>
        </div>
        <CustomModal
                open={openForm}
                close={handleClose}
                formStatus={isChanged}
                title={formtype === 'company' ? "Add New Company" : "Add New User"}
                titleStyle="primary"
                ariaLabelledby="add-new-company-form-title"
                ariaDescribedby="add-new-company--form-description">
            {formtype === 'company' ? 
            <AddCompany token={token} onchange={changeFormStatus} page="home" handleClose={handleClose} />
            : formtype === 'user' ?
            <AddUser token={token} onchange={changeFormStatus} page="home" handleClose={handleClose} companyList={allCompany} />
            : null
            }
        </CustomModal>
      </Layout>
    );
}

export default Home;

