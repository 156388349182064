import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Container, Box } from '@material-ui/core'
import Header from '../components/header'
import { theme } from '../theme/theme'

const useStyles = makeStyles({
    mainContainer: {
        width: '100%',
        position: 'relative',
        height: 'calc(100vh - 130px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.background.primary,
        borderRadius: '10px',
    },
    rightContainer: {
        background: 'url(/images/front-view-motorcyclist-speeding-up.png)',
        borderRadius: '10px',
        width: '52%',
        height: 'calc(100vh - 130px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: 0,
    },

});

const LoginPageLayout = (props) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
                <Header token={props.token}/>
                <Box className={classes.mainContainer}>
                    <Box>
                        {props.children}
                    </Box>
                    <Box className={classes.rightContainer}>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
};

export default LoginPageLayout;